$font-path: './../../fonts' !default;
$image-path: './../../images';
$icon-path: './../../images';

// margin
$margin-bottom-large: 40px;
$margin-bottom-large-lg: 64px;

$margin-bottom-small: 16px;
$margin-bottom-small-lg: 24px;

$margin-bottom-xsmall: 8px;
$margin-bottom-xsmall-lg: 16px;

// colors
$neutralWarmGrey: #F8F6F0;
$primaryTurquoise: #7AD9E0;
$primaryPetrol: #CCE5E9;
$petrolLight: #CCE5E9;
$neutralGrey: #868686;

$textBlack: #000;
$textPetrol: #007C92;
$textTurquoise: #7AD9E0;
$textBlue: #005A8D;
$textRed: #BA3000;

$bgColorInfobox: #F8F6F0;

$stageColorStandard: #7AD9E0;

$stage-colors: (
    'standard': $primaryTurquoise,
    'grey':$neutralWarmGrey,
    'yellow': #FFC739,
    'orange': #F1953A,
    'petrol': #007C92,
    'blue': #005A8D,
);

$stage-fg-colors: (
    'standard': black,
    'grey': black,
    'yellow': black,
    'orange': black,
    'petrol': white,
    'blue': white,
);

$button-fg-colors: (
    'yellow': black,
    'darkPetrol': white,
    'petrol': white,
    'grey': white,
    'cyan': black,
);

$button-bg-colors: (
    'yellow': #FFC739,
    'darkPetrol': #00424A,
    'petrol': #007C92,
    'grey': #868686,
    'cyan': #7AD9E0,
);

$button-bg-highlight-colors: (
    'yellow': #FCB500,
    'darkPetrol': #002B30,
    'petrol': #005B6B,
    'grey': #868686,
    'cyan': #2BBEC9,
);

$box-bg-colors: (
    'lightCyan': #E5F2F4,
    'cyan': #7AD9E0,
    'yellow': #FFC739,
    'petrol': #007C92,
    'lightPetrol': #E5F2F4,
    'darkPetrol': #00424A,
    'red': #BA3000,
    'blue': #005A8D,
    'orange': #F1953A,
    'grey': $neutralWarmGrey,
);

$box-fg-colors: (
    'cyan': black,
    'yellow': black,
    'petrol': white,
    'darkPetrol': white,
    'lightPetrol': black,
    'red': white,
    'blue': white,
    'orange': black,
);



$accordion-inactive-bg: $primaryPetrol;
$accordion-active-bg: #E5F2F4;

$table-headline-bg: $textPetrol;
$table-header-bg: #E5F2F4;
$table-header-color: #00424A;

$tabs-pane-bg: #E5F2F4;

$form-widget-bg: #F8F6F0;
$form-select-bg: #CCE5E9;
$form-error-color: #BA3000;
    
    //// width settings
//$width-content-reduced: 950px;
//$width-content-narrow: 600px;
//$width-header-reduced: 920px;
//$width-header-reduced-with-margin: $width-header-reduced + 16px;
//
//// background colors
//$bg-colors: (
//    'white': white,
//    'black': black,
//    'standard': white,
//    'lightgrey': $lightgrey,
//    //'lightblue': $lightblue,
//    //'blue': $blue,
//    //'darkblue': $darkblue,
//    //'lightorange': $lightorange,
//    //'orange': $orange,
//    //'darkorange': $darkorange,
//    //'lightbeige': $lightbeige,
//    //'beige': $beige,
//    //'darkbeige': $darkbeige,
//    //'warmgrey': $warmgrey,
//    //'lightwarmgrey': $lightwarmgrey,
//);
////
////// text colors on background
////$text-bg-colors: (
////    'white': black,
////    'black': white,
////    'standard': black,
////    'lightblue': white,
////    'blue': white,
////    'darkblue': white,
////    'lightorange': white,
////    'orange': white,
////    'darkorange': white,
////    'lightbeige': black,
////    'beige': black,
////    'darkbeige': black,
////    'warmgrey': white,
////    'lightwarmgrey': white,
////);
//
//// header
//$header-height: 64px;
//$header-height-lg: 88px;
//
//// breadcrumb
//$breadcrumb-link-cl: #555;
//
//// errors
//$color-error-cl: #8e2500;
//$color-error-border: #8e2500;
//
//// spacers
//$spacer-teaser-paragraph: 12px;
//
//// headlines
//$headline-color: $green;
//
//// lists
//$ol-counter-cl: $green;
//
//// links
//$link-color: black;
//$link-color-hover: #555;
//$link-color-hover-filter: invert(34%) sepia(8%) saturate(12%) hue-rotate(20deg) brightness(89%) contrast(87%);
//$icon-link-width: 24px;
//$icon-link-height: 18px;
//$icon-link-padding: 36px;
//
//// forms
//$color-form-bg: #e5e4e3;
//$color-form-button-cl: white;
//$color-form-button-bg: $green;
//$color-form-text-bg: #f2f1ef;
