.mod_post_optin_module {
    .content-text {
        @include marginBottomStd;
    }
    
    .widget {
        h3 {
            @include headlineLevel3;
        }
    }
}