@mixin resetList() {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin imageStandardNoCaption() {
    figure {
        margin-bottom: 0;
    }
    
    figcaption {
        display: none;
    }
    
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

@mixin paragraphStandard() {
    @include fs(18, 27, reg);
    
    @include media-breakpoint-up(lg) {
        @include fsc(20, 32);
    }
}

//@mixin strongStandard() {
//    @include fs(18,27,semibold);
//
//    @include media-breakpoint-up(lg) {
//        @include fs(20,32,semi);
//    }
//}

@mixin linkStandard() {
    @include fs(18, 27, semibold);
    
    display: inline-block;
    text-decoration: none;
    color: $textBlack;
    position: relative;
    padding-left: 36px;
    
    &:hover,
    &:focus {
        text-decoration: underline;
    }
    
    &:before {
        @include addIcon('linkStandardBold', 16px);
        
        position: absolute;
        top: 5px;
        left: 10px;
        z-index: 1;
        //content: 'i';
        //display: inline-block;
        //width: 36px;
        //padding-right: 10px;
    }
    
    &[href^=http] {
        @include setLinkIcon('linkExternalBold');
    }
    
    &[href^=mailto] {
        @include setLinkIcon('linkMailToBold');
    }
    
    &[href^=files] {
        @include setLinkIcon('linkDownloadBold');
    }
    
    @include media-breakpoint-up(md) {
        @include fs(20, 32, semibold);
        
        &:before {
            top: 7px;
        }
    }
}

@mixin inlineLink($size, $offset) {
    &:after {
        @include addIcon('linkExternalBold', $size);
        
        display: inline-block;
        margin-left: 2px;
        //content: 'x';
        //font-size: 20px;
        color: inherit;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        
        transform: translateY(-$offset);
    }
}

@mixin replaceWithIcon($iconWidth, $iconHeight, $iconName) {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    padding: 0;
    border: 0;
    background: none;
    width: $iconWidth;
    height: $iconHeight;
    
    &:before {
        content: ' ';
        display: inline-block;
        width: $iconWidth;
        height: $iconHeight;
        background-image: url('#{$icon-path}/#{$iconName}.svg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
    }
}

//@mixin replaceWithIconFont($iconWidth, $iconHeight) {
//    display: inline-block;
//    font-size: 0;
//    line-height: 0;
//    padding: 0;
//    border: 0;
//    width: $iconWidth;
//    height: $iconHeight;
//
//    &:before {
//        content: '';
//        line-height: $iconHeight;
//        text-align: center;
//        display: inline-block;
//        width: $iconWidth;
//        height: $iconHeight;
//    }
//}

@mixin backgroundBox($bgColor: $bgColorInfobox) {
    padding: 24px;
    background-color: $bgColor;
    
    @include media-breakpoint-only(md) {
        padding: 32px;
    }
    
    @include media-breakpoint-up(md) {
        padding: 40px;
    }
}

@mixin backgroundBoxLarge($bgColor: $bgColorInfobox) {
    padding: 24px;
    background-color: $bgColor;
    
    @include media-breakpoint-only(md) {
        padding: 40px;
    }
    
    @include media-breakpoint-up(lg) {
        padding: 80px;
    }
}

@mixin boxSlider() {
    padding-bottom: 40px;
    
    
    @include media-breakpoint-only(md) {
        padding-bottom: 48px;
    }
    
    @include media-breakpoint-up(md) {
        padding-bottom: 64px;
    }
}

@mixin resetButton() {
    border-width: 0;
    background: transparent;
    padding: 0;
    margin: 0;
}

@mixin buttonCircleIcon($size, $iconSize, $color: 'darkPetrol') {
    @include replaceWithIconFont($size, $size);
    
    background-color: map-get($button-bg-colors, $color);
    border-radius: 50%;
    
    &:before {
        color: map-get($button-fg-colors, $color);
        font-size: $iconSize;
    }
    
    &:hover {
        background-color: map-get($button-bg-highlight-colors, $color);
    }
}

@mixin button($color: 'yellow') {
    @include fs(18, 20, xbold);
    
    background-color: map-get($button-bg-colors, $color);
    color: map-get($button-fg-colors, $color);
    
    display: inline-block;
    max-height: 40px;
    padding: 10px 24px;
    border-width: 0;
    border-radius: 40px;
    text-decoration: none;
    border-color: map-get($button-fg-colors, $color);
    border-style: solid;
    
    &:before {
        //display: none;
    }
    
    &:hover:not([disabled]) {
        background-color: map-get($button-bg-highlight-colors, $color);
        text-decoration: underline;
    }
}

@mixin button-thin($color: 'yellow') {
    @include fs(18, 16, reg);
    
    background-color: map-get($button-bg-colors, $color);
    color: map-get($button-fg-colors, $color);
    
    display: inline-block;
    padding: 11px 20px;
    border-width: 0;
    border-radius: 40px;
    text-decoration: none;
    border-color: map-get($button-fg-colors, $color);
    border-style: solid;
    
    &:before {
        //display: none;
    }
    
    &:hover {
        background-color: map-get($button-bg-highlight-colors, $color);
        text-decoration: underline;
    }
}

@mixin button-transparent-thin() {
    @include button-thin();
    @include buttonAll;
    
    border-width: 1px;
    background-color: transparent;
    
    &:hover {
        background-color: transparent;
    }
}

@mixin buttonInt() {
    &:after {
        @include addIcon('linkCTA', 16px);
        
        line-height: inherit;
        padding-left: 8px;
        transform: translateY(1px);
    }
}

@mixin buttonBack() {
    &:before {
        @include addIcon('linkBackCTA', 16px);
        
        line-height: inherit;
        padding-right: 8px;
        transform: translateY(1px);
    }
}

@mixin buttonAll() {
    &:after {
        @include addIcon('arrowShowAll', 16px);
        
        line-height: inherit;
        padding-left: 8px;
        transform: translateY(1px);
    }
}

@mixin buttonExt() {
    &:after {
        @include addIcon('linkExternalCTA', 16px);
        
        line-height: inherit;
        padding-left: 8px;
        transform: translateY(2px);
    }
}

@mixin buttonDown() {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $neutralWarmGrey;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    
    &:after {
        @include addIcon('accordion-closed', 24px);
        
        line-height: 40px;
        color: black;
    }
}

@mixin buttonUp() {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $neutralWarmGrey;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    
    &:after {
        @include addIcon('accordion-opened', 24px);
        
        line-height: 40px;
        color: black;
    }
}

@mixin buttonNext() {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $neutralWarmGrey;
    margin-top: 0;
    opacity: 1;
    
    &.swiper-button-disabled {
        &:after {
            opacity: 0.5;
        }
    }
    
    &:after {
        @include addIcon('slider-right', 24px);
        
        color: black;
        transform: translateX(2px);
    }
}

@mixin buttonPrev() {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $neutralWarmGrey;
    margin-top: 0;
    opacity: 1;
    
    &.swiper-button-disabled {
        &:after {
            opacity: 0.5;
        }
    }
    
    &:after {
        @include addIcon('slider-left', 24px);
        
        color: black;
    }
}

@mixin addPlayButton() {
    &:before {
        background-image: url('#{$image-path}/play-petrol.svg'); // org
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
        width: 80px;  // org
        height: 80px;  // org
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        bottom: 10px;  // org
        left: 10px;  // org
    }
}

@mixin addLargePlayButton() {
    &:before {
        background-image: url('#{$image-path}/play-petrol-large.svg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
        width: 80px;
        height: 80px;
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        @include media-breakpoint-up(xl) {
            width: 120px;
            height: 120px;
        }
    }
}

@mixin label() {
    @include fs(15, 16, bold);
    
    white-space: nowrap;
    display: inline-block;
    height: auto;
    padding: 6px 10px;
    border-width: 0;
    border-radius: 16px;
}

@mixin linkParent() {
    &:after {
        position: absolute;
        inset: 0;
        content: '';
    }
}

@mixin teaserLinkArrow() {
    @include fs(24, 29);
    
    text-align: right;
    margin-bottom: 0;
    
    a {
        &:before {
            @include addIcon('linkStandardBold', 20px);
            
            text-decoration: none;
            color: black;
        }
        
        &[href^=http] {
            @include setLinkIcon('linkExternalBold');
        }
        
        //
        //
        //&:before {
        //    content: '>';
        //    display: inline-block;
        //    text-decoration: none;
        //    font-size: 20px;
        //    font-weight: 700;
        //    color: black;
        //}
        
        &:after {
            position: absolute;
            inset: 0;
            content: '';
        }
    }
}

@mixin columnBox() {
    background-color: map-get($box-bg-colors, 'darkPetrol');
    color: white;
    padding: 64px 24px;
    
    &.petrol {
        background-color: map-get($box-bg-colors, 'petrol');
        color: map-get($box-fg-colors, 'petrol');
    }
    
    @include media-breakpoint-up(md) {
        padding: 64px 40px;
    }
    
    @include media-breakpoint-up(lg) {
        padding: 80px;
        min-height: 800px;
    }
}

@mixin scrollbars(
    $size: 8px,
    $foreground-color: #CCC,
    $background-color: transparent
) {
    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    
    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}