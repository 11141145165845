.main .ce_column {
    > .content-text.standard,
    > .content-text.text-list,
    > .content-element-group.group-infobox {
        @include marginLG;
    }
    
    > div:last-child {
        margin-bottom: 0 !important;
    }
}

.main > .mod_article:not(.no-spacer) > .inside,
.indent,
.layout_full {
    > .content-text:not(.text-contact-image):not(.text-mmz):not(.text-intro),
    > .content-teaser.teaser-content,
    > .content-headline:not(.headline-landingpage):not(.headline-color):not(.headline-color-rev),
    > .content-accordion,
    > .content-gallery,
    > .content-table:not(.table-fullwidth),
    > .ce_dztabs:not(.tabs-fullwidth),
    > .content-media--link,
    > .content-indent,
    //> .content-swiper.standard,
    .mod_eventreader .header-table,
    > .content-element-group.group-infobox {
        @include media-breakpoint-up(lg) {
            margin-left: calc(33.333% + 12px);
            
            //@include make-col-ready;
            //@include make-col(8);
            //@include make-col-offset(4);
        }
    }
    
    > .mod_newslist,
    > .content-text,
    > .content-hyperlink,
    > .content-list,
    > .content-image,
    > .content-youtube,
    > .content-accordion,
    > .content-gallery,
    > .content-quote,
    > .content-table,
    > .content-media,
    > .media-selection,
    > .media-linklist,
    > .content-swiper,
    > .ce_dztabs,
    > .ce_row:not(.margin-grid),
    > .content-bg-slider,
    > .content-scroll-counter,
    > .content-project-teaser-element,
    > .content-eventtip-teaser-element,
    > .content-teaser.teaser-targetgroup,
    > .content-teaser.teaser-content,
    > .content-teaser.teaser-content-image,
    > .content-teaser.teaser-engagement,
    > .content-element-group.group-infobox {
        @include marginLG;
        
        &.margin-grid-bottom {
            @include marginGridBottom;
        }
        
        > .content-text,
        > .content-list {
            &:not(:last-child) {
                @include marginLG;
            }
        }
    }
}

.group-infobox {
    @include backgroundBox;
    
    @each $name, $color in $box-fg-colors {
        &.#{$name} {
            background-color: map-get($box-bg-colors, $name);
            color: map-get($box-fg-colors, $name);
        }
    }
    
    &.lightpetrol {
        background-color: map-get($box-bg-colors, 'lightPetrol');
        color: map-get($box-fg-colors, 'lightPetrol');
    }
    
    &.darkpetrol {
        background-color: map-get($box-bg-colors, 'darkPetrol');
        color: map-get($box-fg-colors, 'darkPetrol');
    }
    
    h2,
    h3,
    h4 {
        color: inherit;
    }
    
    .content-text {
        &:not(.text-button-list):not(.text-mmz) {
            p > a:not(.inline),
            li > a:not(.inline) {
                color: inherit;
            }
        }
    }
    
    //&.yellow {
    //    background-color: map-get($box-bg-colors, 'yellow');
    //    color: map-get($box-fg-colors, 'yellow');
    //}
    //
    //&.cyan {
    //    background-color: map-get($box-bg-colors, 'lightCyan');
    //}
}