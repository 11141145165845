// bootstrap
@import "styles/settings/bootstrap";
@import "styles/base/bootstrap";

@import '~tippy.js/dist/tippy.css';

// settings
@import "styles/settings/variables";

// mixins
@import "styles/helper/mixins_bootstrap";
@import "styles/helper/mixins_typography";
@import "styles/helper/mixins_iconfont";
@import "styles/helper/mixins_framework";
@import "styles/helper/mixins_spacer";
@import "styles/helper/mixins_headlines";
@import "styles/helper/mixins_elements";

// base
@import "styles/base/reset";
@import "styles/base/fonts";
@import "styles/base/iconfont";
@import "styles/framework/layout";
@import "styles/base/copyright";
@import "styles/base/general";
@import "styles/base/sr";

@import "styles/dev/dev";

// layout
@import "styles/framework/layout";
@import "styles/framework/grid";
@import "styles/framework/stage";
@import "styles/framework/navigation";
@import "styles/framework/breadcrumb";
@import "styles/framework/article";
@import "styles/framework/footer";

// components
@import "styles/components/elements";
@import "styles/components/elementgroup";
@import "styles/components/headline";
@import "styles/components/text";
@import "styles/components/teaser";
@import "styles/components/custom";
@import "styles/components/contact";
@import "styles/components/table";
@import "styles/components/quote";
@import "styles/components/image";
@import "styles/components/video";
@import "styles/components/gallery";
@import "styles/components/links";
@import "styles/components/tabs";
@import "styles/components/accordion";
@import "styles/components/forms";
@import "styles/components/buttons";
@import "styles/components/scrollcounter";
@import "styles/components/search";
@import "styles/components/pagination";

@import "styles/components/news";
@import "styles/components/media";
@import "styles/components/product";
@import "styles/components/profile";
@import "styles/components/project";
@import "styles/components/eventtip";
@import "styles/components/event";
@import "styles/components/job";
@import "styles/components/special";
@import "styles/components/timeline";

@import "styles/components/slider/slider";
@import "styles/components/slider/bgslider";
@import "styles/components/slider/maskslider";

@import "styles/components/swiper";
@import "styles/components/ensa-stage";
@import "styles/components/asa-anniversary-stage";

// extensions
@import "styles/extensions/profile";
