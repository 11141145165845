$body-color: #000;

$grid-gutter-width: 24px;

$grid-breakpoints: (
    xxs: 0,
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths: (
    sm: 528px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1416px
);

