.mod_eventreader,
.mod_online_registration {
    @include marginLG;
}

.mod_eventreader {
    .header-table {
        @include marginLG;
    }
    
    .text-contact {
        @include marginLG;
        
        strong {
            @include fs(24, 26, xbold);
            
            margin-bottom: 2px;
        }
    }
    
    p.link,
    p.reg-link-hint {
        margin-top: 30px;
        margin-bottom: 0;
    }
    
    .content-text,
    .content-accordion,
    .content-table {
        @include marginBottomStd;
    }
    
    .content-element-group {
        @include marginBottomStd;
        
        > div:last-child {
            margin-bottom: 0;
        }
    }
    
    .content-text {
        p:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.mod_online_registration {
    .header-table {
        @include marginLG;
    }
    
    .form-body {
        h2,
        h3 {
            @include headlineLevel3;
            
            margin-top: 32px;
            margin-bottom: 12px;
        }
    }
    
    .form-errors {
        @include marginBottomStd;
        
        //color: $form-error-color;
        
        a {
            color: $form-error-color;
        }
    }
}

.mod_dzeventcalendar {
    .calendar-month {
        @include marginBottomStd;
        
        ul {
            @include resetList;
            
            display: flex;
            flex-wrap: wrap;
            
            li {
                margin-right: 8px;
            }
            
            a {
                @include button-thin();
                
                border-width: 1px;
                background-color: white;
                
                &.next-year {
                    color: $neutralGrey;
                    border-color: $neutralGrey;
                }
                
                &:hover {
                    background-color: inherit;
                    //background-color: transparent;
                }

                &.active {
                    background-color: black;
                    color: white;
                    border-color: white;

                    &:hover {
                        background-color: black;
                        //background-color: transparent;
                    }
                }
                
            }
        }
        
    }
}

.event-list {
    .event-list-container {
        //@include marginBottomStd;
        & + .pagination {
            margin-top: 20px;
        }
    }
    
    .link-container {
        @include marginTopStd;
        
        text-align: right;
    }
}

.mod_dzeventsearchform {
    margin-bottom: 30px;
    
    .filter-element {
        margin-bottom: 1px;
        padding: 24px 40px;
        background: #CCE5E9;
        color: black;
        position: relative;
        
        &.open {
            background: map-get($box-bg-colors, 'lightPetrol');
        }
        
        .change-filter {
            @include buttonDown;
            
            background-color: white;
            //@include setLinkIcon('accordion-closed');
            //@include fs(18,20,ita);
            
            position: absolute;
            top: 19px;
            right: 33px;
            z-index: 1;
            
            span {
                display: none;
            }
            
            //background-color: blue;
            //
            //color: white;
            //padding: 6px 10px;
            //min-width: 100ps;
            //text-align: center;
            //
            //&:hover {
            //    background-color: orange;
            //}
            //
            //span {
            //    display: none;
            //    &.active {
            //        display: inline;
            //    }
            //}
            
            &.open {
                @include buttonUp;
                
                background-color: white;
                
            }
        }
        
        .row {
            padding-bottom: 15px;
        }
        
        > label, .filter-child > label, .columns > label {
            @include fs(28, 34, semibold);
            
            display: block;
            //color: white;
        }
        
        &.fe-text, &.fe-select {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        
        &.fe-checkbox {
            padding-top: 15px;
            padding-bottom: 5px;
            
            > label {
                @include fs(18, 20);
                
                display: inline-block;
                margin-left: 10px;
            }
        }
        
        &.fe-text-zip, &.fe-text-zip-range, &.fe-date-monthyear {
            padding-top: 15px;
            padding-bottom: 15px;
            
            > label {
                display: inline-block;
                margin-right: 25px;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
        
        &.fe-text-zip-range {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            
            > input {
                background-color: white;
                border: 1px solid black;
                border-radius: 5px;
                height: 36px;
                padding: 0 8px;
                width: 100px;
            }
        }
        
        //input[type="text"] {
        //    padding-left: 10px;
        //    position: relative; top: -3px;
        //    background-color: white; border: 0; height: 33px; width: 70px;
        //}
        
        select {
            @include fs(16, 16, reg);
            
            display: inline-block;
            position: relative;
            top: -3px;
            border: 0;
            border-radius: 4px;
            padding: 10px 16px 9px 16px;
            background-color: $neutralWarmGrey;
            
            option {
                @include fs(16, 16, reg);
            }
        }
        
        .cbm-selected-values {
            @include fs(15, 18, semibold);
            
            margin-top: 8px;
            //margin-bottom: 8px;
        }
        
        .cbm-container {
            display: none;
            padding: 15px 5px 5px 5px;
        }
        
        .cbm-item-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
        
        //.cbm-item-container {
        //    @include clearfix;
        //    margin-bottom: 3px; font-size: 1.083em;
        //    > label {
        //        @include fs(18,20);
        //
        //        display: block;
        //        width:calc(100% - 25px);
        //        //color: white;
        //        margin-left: 25px;
        //    }
        //    > input { float:left; position: relative; top: 5px; }
        //    &:last-child {
        //        margin-bottom: 15px;
        //    }
        //}
        
        .cbm-item-container {
            margin-right: 10px;
            margin-bottom: 10px;
            
            input {
                position: absolute;
                clip: rect(0, 0, 0, 0);
                pointer-events: none;
            }
            
            label {
                &:after {
                    @include addIcon('filter-add', 16px);
                    
                    font-weight: bold;
                    //margin-left: 10px;
                    //width: 1rem;
                    //height: 1rem;
                    //display: inline-block;
                    //content: "";
                    vertical-align: -.125em;
                    //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'><path d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'/></svg>");
                    //background-repeat: no-repeat;
                    //background-size: 1rem 1rem;
                }
            }
            
            input:checked + label {
                background-color: black;
                color: white;
                
                &:after {
                    @include addIcon('filter-close', 16px);
                    
                    font-weight: normal;
                    //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z'/></svg>");
                }
            }
        }
        
        .cbm-all-or-nothing {
            margin-top: 16px;
            
            a {
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
        
        .rbm-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            //display: inline; margin-left: 20px;
            //> span {
            //    @include fs(18,20);
            //    //color: white;
            //}
            
            > label {
                @include fs(20, 20);
                
                margin-left: 36px;
                margin-right: 24px;
            }
        }

        
        .rbm-item-container {
            //display: inline; margin-right: 5px;
            //display: flex;
            //align-items: center;
            margin-right: 16px;
            //
            //label {
            //    @include fs(20, 20);
            //    //color: white;
            //    padding-left: 4px;
            //}
            //
            //input {
            //    position: relative;
            //    top: -2px;
            //}
            

        }
        
        .radio-item-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
        
        .radio-item-container {
            margin-right: 10px;
            margin-bottom: 10px;
            
            input {
                position: absolute;
                clip: rect(0, 0, 0, 0);
                pointer-events: none;
            }
            
            .button-transparent-thin {
                &:after {
                    display: none;
                }
                
            }
            
            input:checked + label {
                background-color: black;
                color: white;
            }
        }
        
    }
    
    .submit-container {
        padding-top: 24px;
        //text-align: right;
        input[type="submit"] {
            @include button();
        }
    }
    
}

.mod_dzeventsearchresult {
    .pagination {
        margin-top: 24px;
    }
}
