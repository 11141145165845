.content-quote {
    img {
        max-width: 100%;
        height: auto;
    }
    
    figcaption {
        display: none;
    }
    
    p {
        @include fs(32, 32, xbold);
        
        cite {
            @include fs(28, 34, bold);
            
            display: block;
            margin-top: 16px;
            
            > span {
                @include fs(16, 16, reg);
                
                display: block;
                margin-top: 4px;
            }
        }
    }
    
    figure {
        position: relative;
        
        &:after {
            background-image: url('#{$image-path}/quotation-marks.svg');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
            width: 80px;
            height: 63px;
            content: '';
            position: absolute;
            z-index: 1;
            display: block;
            bottom: -39px;
            left: 24px;
        }
    }
    
    &.standard {
        figure:after {

        }
    }
    
    &.image {
        .image-container {
            margin-bottom: 55px;
        }
    }
    
    &.standard:not(.image) {
        .quote-container {
            &:before {
                background-image: url('#{$image-path}/quotation-marks.svg');
                background-repeat: no-repeat;
                background-position: top left;
                background-size: contain;
                width: 48px;
                height: 38px;
                content: '';
                //position: absolute;
                //z-index: 1;
                //top: 24px;
                //right: -24px;
                //bottom: auto;
                //left: auto;
                display: block;
                margin-bottom: 8px;
            }
        }
    }
    
    &.quote-column {
        @include columnBox;
        
        .quote-container {
            &:before {
                background-image: url('#{$image-path}/quotation-marks-white.svg');
                background-repeat: no-repeat;
                background-position: top left;
                background-size: contain;
                width: 80px;
                height: 63px;
                content: '';
                display: block;
                margin-bottom: 8px;
            }
        }
    }
    
    @include media-breakpoint-only(md) {
        p {
            @include fsc(40, 40);
        }
    }
    
    @include media-breakpoint-only(lg) {
        &.standard.image {
            .quote-container {
                &:before {
                    background-image: url('#{$image-path}/quotation-marks.svg');
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    width: 48px;
                    height: 38px;
                    content: '';
                    //position: absolute;
                    //z-index: 1;
                    //top: 24px;
                    //right: -24px;
                    //bottom: auto;
                    //left: auto;
                    display: block;
                    margin-bottom: 8px;
                }
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        &.quote-centered {
            .image-container {
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
            }
            
            figure {
                width: 360px;
                
                &:after {
                    top: 24px;
                    right: -24px;
                    bottom: auto;
                    left: auto;
                }
            }
            
            p {
                @include fsc(48, 48);
            }
        }
        
        &.standard.image {
            @include make-row();
            
            align-items: center;
            
            figure {
                &:after {
                    display: none;
                }
            }
            
            &.image {
                .image-container {
                    @include make-col-ready;
                    @include make-col(4);
                    
                    margin-bottom: 0;
                }
                
                .quote-container {
                    @include make-col-ready;
                    @include make-col(8);
                }
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        p {
            @include fsc(48, 48);
        }
        
        &.standard.image {
            @include make-row();
            
            figure {
                position: relative;
                
                &:after {
                    background-image: url('#{$image-path}/quotation-marks.svg');
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    width: 80px;
                    height: 63px;
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 24px;
                    right: -24px;
                    bottom: auto;
                    left: auto;
                    display: block;
                }
            }
            
            &.image {
                .image-container {
                    @include make-col(3);
                }
                
                .quote-container {
                    @include make-col(8);
                    @include make-col-offset(1);
                }
            }
        }
    }
}