@keyframes pin {
    0% {
        transform: translate3d(15%,0,0) rotateZ(10deg); }
    100% {
        transform: translate3d(-15%,0,0) rotateZ(-10deg); }
}

@keyframes pin2 {
    0% {
        transform: translate3d(-15%,0,0) rotateZ(-10deg); }
    100% {
        transform: translate3d(15%,0,0) rotateZ(10deg); }
}

@keyframes globe {
    0% {
        transform: rotateZ(0deg); }
    45% {
        transform: rotateZ(10deg); }
    50% {
        transform: rotateZ(180deg); }
    95% {
        transform: rotateZ(190deg); }
    100% {
        transform: rotateZ(360deg); }
}

@keyframes logo {
    0% {
        transform: scale(1); }
    100% {
        transform: scale(1.2); }
}

.ensa-anniversary {
    .header-navigation {
        .inside {
            padding-top: 25px;
        }
    }
}

.ensastage {
    width: 100%;
    height: 600px;
    position: relative;
 
    .layer {
        width: 100%;
        height: inherit;
        position: relative;
    }
    
    &-path {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        
        display: none;
        
            height: auto;
            width: 100%;
    }
    
    &-logo {
        position: absolute;
        bottom: 63vw;
        left: 0;
        z-index: 1;
        
        width: 190px;
        
            animation: logo 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
            transform-origin: center;
    }
    
    &-text1 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        //width: 577px;
        
        h1 {
            @include fs(40,40,xbold);
            
            margin-bottom: 8px;
        }

        p {
            @include fs(20,24,med);
        }
        
        span {
            color: $textPetrol;
        }
    }
    
    &-image {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        
        //img {
            width: 100%;
            max-width: 100%;
            height: auto;
        //}
    }
    
    &-globe {
        position: absolute;
        bottom: 52vw;
        right: 5vw;
        z-index: 1;
        width: 100px;
        height: auto;
        
        animation: globe 6s 0.1s infinite linear;
        transform-origin: center;
    }
    
    
    &-pin {
        position: absolute;
        z-index: 1;
        height: auto;
        display: none;
        transform-origin: 50% 90%;
    }
    
    &-pin1 {
        width: 19px;
        animation: pin 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
    
    &-pin2 {
        width: 12px;
        animation: pin2 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
    
    @include media-breakpoint-up(sm) {
        height: 437px;
        
        &-logo {
            bottom: 0;
        }
        
        &-path,
        &-pin {
            display: block;
        }
        
        &-pin1 {
            left: 184px;
            bottom: 220px;
        }
        
        &-pin2 {
            left: 158px;
            bottom: 110px;
        }
        
        &-image {
            width: 306px;
        }
        
        &-globe {
            bottom: 174px;
            width: 94px;
        }
    }
    
    @include media-breakpoint-up(md) {
        height: 582px;
        
        &-text1 {
            h1 {
                @include fsc(56,56);
            }
        }
        
        &-logo {
            width: 259px;
        }
        
        &-pin1 {
            left: 248px;
            bottom: 300px;
            width: 25px;
        }
        
        &-pin2 {
            left: 213px;
            bottom: 148px;
            width: 17px;
        }
        
        &-image {
            width: 414px;
        }
        
        &-globe {
            bottom: 235px;
            width: 127px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        height: 473px;
        
        &-text1 {
            width: 396px;
            h1 {
                margin-bottom: 16px;
            }
        }
        
        &-pin1 {
            left: 354px;
            bottom: 400px;
            width: 32px;
        }
        
        &-pin2 {
            left: 308px;
            bottom: 205px;
            width: 22px;
        }
        
        &-image {
            width: 539px;
        }
        
        &-globe {
            top: 0;
            bottom: auto;
            width: 165px;
        }
    }
    
    @include media-breakpoint-up(xl) {
        height: 562px;
        
        &-path {
            width: 1024px;
        }
        
        &-text1 {
            width: 455px;
            h1 {
                @include fsc(64,64);
                margin-bottom: 24px;
            }
            
            p {
                @include fsc(24,29);
            }
        }
        
        &-logo {
            width: 312px;
        }
        
        &-pin1 {
            left: 423px;
            bottom: 420px;
            width: 38px;
        }
        
        &-pin2 {
            left: 368px;
            bottom: 230px;
            width: 26px;
        }
        
        &-image {
            width: 641px;
        }
        
        &-globe {
            width: 197px;
        }
    }
    
    @include media-breakpoint-up(xxl) {
        height: 712px;
        
        &-path {
            width: 1300px;
        }
        
        &-text1 {
            width: 577px;
            h1 {
                @include fsc(80,80);
                margin-bottom: 24px;
            }
        }
        
        &-logo {
            width: 402px;
        }
        
        &-pin1 {
            left: 507px;
            bottom: 550px;
            width: 49px;
        }
        
        &-pin2 {
            left: 462px;
            bottom: 290px;
            width: 33px;
        }
        
        &-image {
            //img {
                width: 816px;
            //}
        }
        
        &-globe {
            width: 250px;
        }
    }
}

