//@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
//    $min: map-get($breakpoints, $name);
//
//    @return if($min != 0, $min, null);
//}
//
//@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
//    $min: breakpoint-min($name, $breakpoints);
//
//    @if $min {
//        @media (min-width: $min) {
//            @content;
//        }
//    } @else {
//        @content;
//    }
//}

@mixin make-container-maxwidths() {
    @include media-breakpoint-up(sm) {
        max-width: map-get($container-max-widths, 'sm');
    }
    @include media-breakpoint-up(md) {
        max-width: map-get($container-max-widths, 'md');
    }
    @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, 'lg');
    }
    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, 'xl');
    }
    @include media-breakpoint-up(xxl) {
        max-width: map-get($container-max-widths, 'xxl');
    }
}
