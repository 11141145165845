.anchortarget {
    scroll-margin-top: 120px;
}

@include media-breakpoint-down(md) {
    .hide-on-mobile-sm {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    .show-on-mobile-sm {
        display: none;
    }
}

.modal-dialog {
    &:not(.video-short) {
        @include make-container-maxwidths;
    }
    
    //max-width: 10000px;
    
    .modal-header {
        border-bottom: 0;
    }
    
    .modal-content {
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }
    
    .modal-footer {
        border-radius: 0;
        background-color: white;
    }
    
    .btn-close {
        background-image: url('#{$image-path}/modal-close.svg');
        opacity: 1;
        width: 20px;
        height: 20px;
    }
}

.modal-dialog.video-short {
    width: 315px;
    margin: 0 auto !important;

    .modal-body {
        background-color: white;
        padding: 0;
    }
}
