//.mod_navigation {
//    //border-bottom: 2px solid darkgrey;
//
//    .level_1 {
//        display: flex;
//        list-style-type: none;
//
//        > li {
//            margin-right: 40px;
//            > a, strong {
//                text-transform: uppercase;
//            }
//        }
//    }
//
//    .level_2 {
//        padding-left: 1.4rem;
//    }
//}

body {

}

//header {
//    background: #f5b335;
//    height: 40px;
//    position: fixed;
//    top: 0;
//    transition: top 0.2s ease-in-out;
//    width: 100%;
//}

.header-navigation {
    padding-top: 100px;
    
    .header-sticky-container {
        position: fixed;
        top: 0;
        z-index: 1000;
        transition: top 0.2s ease-in-out;
        width: 100%;
        
        &.nav-up {
            top: -100px;
        }
        
        &.nav-down.nav-scrolled {
            .inside {
                box-shadow: 0px 5px 19px 9px rgba(0,0,0,0.1);
            }
        }
    }
    
    @each $name, $color in $stage-colors {
        body.#{$name} & {
            background-color: $color;
        }
    }
    
    .inside {
        background-color: white;
    }
    
    @include media-breakpoint-up(lg) {
        .inside {
            margin: 0 2%;
        }
    }
    
    @include media-breakpoint-up(xl) {
        .inside {
            //margin: 0 80px;
        }
    }
    
    @include media-breakpoint-up(xxl) {
        .inside {
            margin: 0 80px;
        }
    }
}


.main-navigation {
    background-color: white;
    
    .navbar {
        padding: 0 24px;
    }
    
    .navbar-brand {
        padding: 0 0 24px 0;
    }
    
    .level_1 {
        > li {
            //&.nav-special {
            //    padding-bottom: 16px;
            //}
            
            > .nav-link {
                &.dropdown-toggle:after {
                    display: none;
                }
                
                &.submenu:after {
                    @include addIcon('arrow-menu-closed', 18px);
                    
                    padding-left: 5px;
                    transform: translateY(1px);
                }
                
                color: black;
                padding: 0;
                
                &:not(.nav-special):not(.nav-search) {
                    @include fs(20, 30, semibold);
                }
                
                &.nav-special {
                    span {
                        @include fs(18, 20, xbold);
                        
                        display: inline-block;
                        padding: 9px 20px 7px 20px;
                        background-color: #FFC739;
                        border-radius: 40px;
                    }
                }
                
                &.nav-search {
                    span {
                        @include replaceWithIconFont(20px, 20px);
                        @include setLinkIcon('search-menu');
                    }
                }
            }
        }
    }
    
    .navbar-expand-xl .navbar-nav .level_2 {
        .nav-link {
            @include fs(18, 21, semibold);
            
            padding: 6px 0;
            color: black;
            
            &:hover {
                text-decoration: underline;
            }
        }
        
        > li:first-child {
            > .nav-link {
                padding-bottom: 3px;
                border-bottom: 1px solid black;
                margin-bottom: 6px;
                position: relative;
                padding-right: 30px;
                white-space: nowrap;
                
                &:after {
                    position: absolute;
                    top: 4px;
                    right: 0;
                    z-index: 1;
                    display: inline-block;
                    
                    @include addIcon('overview', 20px);
                }
            }
        }
    }
    

    
    .dropdown-menu {
        padding: 16px 24px;
        border: 0;
    }
    
    .navbar-toggler {
        width: 35px;
        height: 25px;
        margin-bottom: 20px;
        position: relative;
        border: 0;
        padding: 0;
        
        &:focus {
            box-shadow: none;
        }
        
        //&:after {
        //    background-image: none;
        //
        //    @include addIcon('menu', 25px);
        //
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //    z-index: 100;
        //    //background-color: red;
        //}
        
        &-icon {
            display: none;
            background-image: none;
            
            @include addIcon('contrast', 30px);
            
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            background-color: red;
        }
    }
    
    .navbar-container {
        width: 100%;
        //padding: 0 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
    }
    
    
    @include media-breakpoint-up(lg) {
        //.navbar-container {
        .navbar {
            padding: 0 40px;
        }
        //}
    }
    
    @include media-breakpoint-down(xl) {
        .content-container {
            @include make-container();
            @include make-container-maxwidths;
            
            padding-bottom: 24px;
        }
        
        .dropdown-menu {
            padding: 16px 0 16px 16px;
        }
        
        .level_1 {
            > li {
                &.nav-special,
                &.nav-search {
                    text-align: center;
                    padding-top: 18px;
                }
                
                > .nav-link {
                    &.submenu.show:after {
                        @include addIcon('arrow-menu-opened', 18px);
                    }
                    
                    &:not(.nav-special):not(.nav-search) {
                        padding: 18px 0 16px 0;
                        border-bottom: 1px solid black;
                        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    
                    &.nav-special {
                        display: inline-block;
                    }
                    
                    &.nav-search {
                        display: inline-block;
                    }
                }
            }
        }
        
        .navbar-expand-xl .navbar-nav .level_2 {
            .nav-link {
            }
            
            > li:first-child {
                > .nav-link {
                    border-color: darkgrey;
                }
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        .navbar {
            //.navbar-container {
            width: 100%;
            //padding: 0 24px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-end;
            //}
        }
        
        .navbar-container {
            align-items: flex-end;
        }
        
        .navbar-collapse {
            flex-grow: 0;
        }
        
        .dropdown-menu[data-bs-popper] {
            top: 52px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 280px;
            border-radius: 16px;
        }
        
        .level_1 {
            align-items: flex-end;
            
            > li {
                padding: 0 10px;
                
                //&.nav-special {
                //    padding-bottom: 16px;
                //    padding-right: 0;
                //}
                
                > .nav-link {
                    color: black;
                    padding: 0;
                    
                    &:not(.nav-special):not(.nav-search) {
                        @include fs(20, 30, semibold);
                        
                        padding-bottom: 20px;
                        
                        &:hover {
                            padding-bottom: 16px;
                            border-bottom: 4px solid black;
                        }
                    }
                    
                    &.nav-special {
                        padding-bottom: 16px;
                        
                        //span {
                        //    @include fs(18, 20, xbold);
                        //
                        //    display: inline-block;
                        //    padding: 9px 20px 7px 20px;
                        //    background-color: #FFC739;
                        //    border-radius: 40px;
                        //}
                        
                        &:hover {
                            padding-bottom: 12px;
                            border-bottom: 4px solid black;
                        }
                    }
                    
                    &.nav-search {
                        padding-bottom: 20px;
                        
                        &:hover {
                            padding-bottom: 16px;
                            border-bottom: 4px solid black;
                        }
                    }
                }
            }
        }
        
        //.navbar-expand-lg .navbar-nav .level_2 {
        //    .nav-link {
        //
        //        @include fs(16, 19, semibold);
        //
        //        padding: 6px 0;
        //        color: black;
        //
        //    }
        //
        //    > li:first-child {
        //        > .nav-link {
        //            padding-bottom: 3px;
        //            border-bottom: 1px solid black;
        //            margin-bottom: 3px;
        //        }
        //    }
        //}
    }
    
    @include media-breakpoint-up(xl) {
        //.inside {
        //    margin: 0 80px;
        //}
    }
}

.func-navigation {
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;
    
    ul {
        display: flex;
    }
    
    .level_1 {
        a,
        strong {
            @include replaceWithIconFont(20px, 20px);
            
            &:hover:before {
                border-bottom: 1px solid black;
            }
        }
        
        
        li {
            //@each $name in ['language-en', 'language-de', 'contrast', 'gebaerdensprache', 'leichte-sprache'] {
            @each $name in ['language-en', 'language-de', 'contrast', 'gebaerdensprache'] {
                &.icon-#{$name} {
                    a,
                    strong {
                        @include setLinkIcon($name);
                        
                        &:hover:before {
                            padding-bottom: 2px;
                        }
                    }
                }
            }
            
            &.icon-leichte-sprache {
                a,
                strong {
                    &:before {
                        height: 20px;
                        background-image: url('#{$image-path}/icon_easy_language.svg');
                        background-repeat: no-repeat;
                        background-position: top center;
                        background-size: 18px 18px;
                    }
                    
                    &:hover:before {
                        height: 20px;
                    }
                }
            }
            
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
    
    @include media-breakpoint-up(md) {
        padding-left: 40px;
        padding-right: 40px;
    }
}
