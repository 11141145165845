#stage {
    padding-top: 58px;
    
    //color: white;
    
    body & {
        min-height: 360px;
    }
    
    body.no-stage & {
        display: none !important;
    }
    
    @each $name, $color in $stage-colors {
        body.#{$name} & {
            @include stageBg($name, 360);
            
            color: map-get($stage-fg-colors, $name);
        }
    }
    
    @include media-breakpoint-up(lg) {
        body & {
            min-height: 320px;
        }
        
        @each $name, $color in $stage-colors {
            body.#{$name} & {
                @include stageBg($name, 320);
            }
        }
        
        //body.standard & {
        //    @include stageBg('standard', 320);
        //}
        //
        //body.grey & {
        //    @include stageBg('grey', 320);
        //}
    }
    
    @include media-breakpoint-up(xl) {
        body & {
            min-height: 360px;
        }
        
        @each $name, $color in $stage-colors {
            body.#{$name} & {
                @include stageBg($name, 360);
            }
        }
        
        //body.standard & {
        //    @include stageBg('standard', 360);
        //}
        //
        //body.grey & {
        //    @include stageBg('grey', 360);
        //}
    }
    
    @include media-breakpoint-up(xxl) {
        body & {
            min-height: 400px;
        }
        
        @each $name, $color in $stage-colors {
            body.#{$name} & {
                @include stageBg($name, 400);
            }
        }
        //
        //body.standard & {
        //    @include stageBg('standard', 400);
        //}
        //
        //body.grey & {
        //    @include stageBg('grey', 400);
        //}
    }
}


.stage-container {
    @include media-breakpoint-down(lg) {
        .col-headline {
            min-height: 137px;
        }
        
        .col-image {
            margin-top: 32px;
        }
    }
    
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}