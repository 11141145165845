@keyframes timeline-fadein {
    0% {
        opacity: 0; }
    30% {
        opacity: 0; }
    100% {
        opacity: 1; }
}

.content-timeline {
    .swiper-wrapper {
        //transition-timing-function: ease-in-out;
    }
    
    .swiper-slide {
        &-active {
            .content-container {
                //opacity: 0;
                animation: timeline-fadein 2.5s 0s ease-in;
            }
        }
    }
    
    &.swiping {
        .swiper-slide {
            //margin-right: $grid-gutter-width;
        }
    }
    
    .content-teaser.teaser-standard-hor {
        .content {
            padding-left: 8px;
        }
    }
}

.timeline {
    &-nav {
        width: 100%;
        //height: calc(39px + 55px);
        height: calc(57px + 68px);
        position: relative;
        overflow: hidden;
        
        .swiper-button-prev {
            @include buttonPrev;
        }
        
        .swiper-button-next {
            @include buttonNext;
        }
        
        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            background-color: #F1953A;
            margin-top: 10px;
            
            &.swiper-button-disabled {
                background-color: #F8CA9C;
                
                &:after {
                    opacity: 1;
                }
            }
        }

    }
    
    &-prev,
    &-next {
        position: absolute;
        top: 0;
        height: 60px;
        z-index: 10;
    }
    
    &-prev {
        left: 0;
        padding-right: 30px;
        background: linear-gradient(to right, $neutralWarmGrey 0, $neutralWarmGrey 80%, transparent 100%);
    }
    
    &-next {
        right: 0;
        padding-left: 30px;
        background: linear-gradient(to left, $neutralWarmGrey 0, $neutralWarmGrey 80%, transparent 100%);
    }
    
    &-mask {
        position: relative;
        z-index: 1;
        width: 100%;
        height: inherit;
        //mask-image: linear-gradient(to right, transparent, transparent 5%, black 7%, black 93%, transparent 95%, transparent 100%);
    }
    
    &-nav-pane {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        flex-wrap: nowrap;
        
        transition: left 1s;
    }
    
    
    &-padding,
    &-dot {
        padding-bottom: calc(6px + 9px);
        border: 0;
        border-bottom: 2px solid #96918B;
        
        //@include fs(32, 39, xbold);
        @include fs(48, 57, xbold);
    }
    
    &-padding {
        //width: 40px;
        width: 75px;
    }
    
    &-dot {
        position: relative;
        width: 160px;
        background-color: transparent;
        //padding-bottom: calc(6px + 9px);
        //border-bottom: 2px solid #96918B;
        //
        //@include fs(32, 39, xbold);
        
        &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            height: 20px;
            background-color: #80BDC8;
            border-radius: 50%;
        }
        
        
        &.active {
            color: #F1953A;
            
            &:after {
                background-color: #F1953A;
            }
        }
    }
    
    &-swiper {
        overflow: hidden;
    }
    
    @include media-breakpoint-up(sm) {
        &-nav {
            height: calc(57px + 68px);
        }
        
        &-dot,
        &-padding {
            @include fs(48, 57, xbold);
        }
        
        
        &-padding {
            width: 55px;
        }
        
        &-dot {
            
            
            width: 132px;
        }
    }
    
    @include media-breakpoint-up(md) {
        
        &-padding {
            width: 65px;
        }
        
        &-dot {
            width: 143px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        &-nav {
            height: calc(67px + 75px);
        }
        
        &-dot,
        &-padding {
            @include fs(56, 67, xbold);
        }
        
        &-padding {
            width: 65px;
        }
        
        &-dot {
            
            
            width: 161px;
        }
    }
    
    @include media-breakpoint-up(xl) {
        &-padding {
            width: 70px;
        }
        
        &-dot {
            width: 163px;
        }
    }
    
    @include media-breakpoint-up(xxl) {
        &-padding {
            width: 78px;
        }
        
        &-dot {
            width: 179px;
        }
    }
    
    
}

.mod_article.petrol {
    .timeline {
        &-prev {
            background: linear-gradient(to right, map-get($box-bg-colors, 'petrol') 0, map-get($box-bg-colors, 'petrol') 50%, transparent 100%);
        }
        
        &-next {
            background: linear-gradient(to left, map-get($box-bg-colors, 'petrol') 0, map-get($box-bg-colors, 'petrol') 50%, transparent 100%);
        }
        
        &-nav {
            .swiper-button-prev,
            .swiper-button-next {
                background-color: #FFC739;
                
                &.swiper-button-disabled {
                    background-color: #FFE39C;
                }
            }
            

        }
        
        &-padding,
        &-dot {
            border-bottom: 2px solid white;
        }
        
        &-dot {
            color: white;
            
            &:after {
                background-color: #FFC739;
            }
            
            &.active {
                color: #FFC739;
                
                &:after {
                    background-color: #FFC739;
                }
            }
            
        }
    }
}