.profile-homepage {
    //overflow: hidden;
    &__headline {
        margin-bottom: 24px;
        
        > * {
            @include fs(48, 43, xbold);
            
            span {
                color: $textPetrol;
            }
        }
    }
    
    .navigation {
        position: relative;
        height: 64px;
        
        .container {
            position: relative;
        }
    }
    
    //-webkit-mask-image: linear-gradient( to right,transparent,
    //    black 20%,
    //    black 80%,
    //    transparent 100%);
    
    
    .swiper-button-prev,
    .swiper-button-next {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
    }
    
    .swiper-button-prev {
        @include buttonPrev;
        
        right: 58px
    }
    
    .swiper-button-next {
        @include buttonNext;
        
        right: 12px;
    }
    
    .video-swiper {
        //overflow-x: visible;
    }
    
    .profile-slider-container {
        position: relative;
    }
    
    @include media-breakpoint-up(md) {
        &__headline {
            margin-bottom: 32px;
            
            > * {
                @include fsc(80, 72);
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        &__headline {
            margin-bottom: 40px;
            
            > * {
                @include fsc(96, 86);
            }
        }
        
        .navigation {
            //display: none;
            
            position: absolute;
            top: -60px;
            right: 0;
            z-index: 2;
        }
    }
    
    @include media-breakpoint-up(xl) {
        &__headline {
            margin-bottom: 24px;
        }
    }
    
    @include media-breakpoint-between(sm, lg) {
        .profile-teaser__list {
            overflow: hidden;
        }
        
        .video-swiper {
            overflow: visible;
            width: 315px;
        }
    }
}

.profile-teaser__list {
    //overflow: hidden;
}

.profile-teaser__intro {
    p {
        @include fs(20, 24, semibold);
    }
    
    //padding-left: calc((100% - 1416px) / 2);
    @include media-breakpoint-up(xl) {
        p {
            @include fsc(24, 29);
        }
    }
}

.profile-teaser {
    @include imageStandardNoCaption;
    //&__list {
    //    display: flex;
    //    justify-content: flex-start;
    //    margin: 0 -15px;
    //}
    
    figcaption {
        display: none;
    }
    
    &__item {
        @include imageStandardNoCaption;
        
        //width: 25%;
        //padding: 15px;
        //margin-bottom: 20px;
        
        .inside {
            height: 100%;
            //border-radius: 10px;
            //padding: 15px;
            //border: 1px solid grey;
            
            .content-container {
                p {
                    @include fs(16, 24);
                }
            }
            
            > button {
                @include addPlayButton;
                
                position: relative;
                border: 0;
                padding: 0;
                margin-bottom: 20px;
                
                > figure {
                    margin: 0;
                }
            }
        }
        
    }
}

.profile-modal {
    .content-container {
        padding: 12px 16px;
        
        p {
            @include fs(16, 24);
            
            margin-bottom: 0;
        }
    }
}

.profile-detail {
    .chapter-link {
        @include fs(20,22,semibold);
        
        display: inline-flex;
        //align-items: flex-start;
        position: relative;
        width: 217px;
        height: 100%;
        max-width: 1000px;
        border: 0;
        padding-right: 20px;
        border-right: 1px solid black;
        margin-right: 24px;
        text-align: left;
        vertical-align: top;
        hyphens: auto;
        background-color: transparent;
        
        padding-bottom: 80px;
        
        &:before {
            background-image: url('#{$image-path}/play-petrol.svg');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
            width: 56px;
            height: 56px;
            content: '';
            position: absolute;
            z-index: 1;
            display: block;
            
            bottom: 0;
            left: 0;
        }
        
        
        &.selected {
            //background-color: map-get($box-bg-colors, 'petrol');
            //color: white;
            text-decoration: underline;
        }
        
        &.playing {
            &:before {
                background-image: url('#{$image-path}/pause-lightblue.svg');
                //background-color: grey;
                //border-radius: 50%;
            }
        }
    }
    
    .profile-iframe-container {
        position: relative;
        line-height: 0;
    }
    
    .profile-cover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        
        button {
            @include addPlayButton;
            
            &:before {
                cursor: pointer;
            }
            
            width: 100%;
            height: 100%;
            border: 0;
            position: relative;
        }
        
        img {
            width: 100%;
            height: auto;
        }
    }
    
    .profile-chapter-container {
        margin-top: 40px;
        
        h3 {
            @include headlineLevel2;
            
            margin-bottom: 24px;
        }

        .scrollpane {
            @include scrollbars();
            
            overflow-x: scroll;
        }
        
        .scrollcontent {
        }
    }
    
    .profile-chapters {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;

        > div {
            padding-bottom: 20px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-end;
        
        .profile-chapter-container {
            margin-top: 0;
            
            h3 {
                margin-bottom: 32px;
            }
            
            .scrollpane {
                height: 200px;
                overflow-y: scroll;
                overflow-x: hidden;
            }
        }
        
        .profile-chapters {
            display: block;
            
            > div {
                padding-bottom: 0;
                
                
                &:not(:last-child) {
                    .chapter-link {
                        margin-bottom: 24px;
                    }
                }
            }
        }
        
        .chapter-link {
            @include fsc(24,29);
            
            display: block;
            width: auto;
            height: auto;
            padding-bottom: 24px;
            padding-right: 100px;
            border: 0;
            border-bottom: 1px solid black;
            
            &:before {
                bottom: auto;
                top: 0;
                left: auto;
                right: 0;
            }
        }
    }
}