.content-text.text-contact,
.content-text.text-contact-image {
    strong {
        @include fst(semibold);
    }
    
    h2,
    h3,
    h4 {
        @include fs(24,26,xbold);
        
        margin-bottom: 6px;
    }
    
    p {
        @include fs(18,27);
        
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        
        a {
            color: inherit;
        }
    }
    
    .mod_eventreader &,
    .mod_article > .inside > &,
    .layout_full > & {
        @include media-breakpoint-up(xxl) {
            h2,
            h3,
            h4 {
                @include fsc(32,35);
                
                margin-bottom: 6px;
            }
            
            p {
                @include fsc(20,32);
                
                //&:not(:last-child) {
                //    margin-bottom: 16px;
                //}
            }
        }
    }
    
}

.content-text.text-contact {
    @include backgroundBox;
    
    @include media-breakpoint-up(lg) {
        .ce_column & {
            padding: 24px;
        }
    }
}

.content-text.text-contact-image {
    border-radius: 16px;
    
    .mod_article > .inside > &,
    .layout_full > & {
        overflow-x: hidden;
        background-color: $neutralWarmGrey;
        //border-radius: 16px 16px 0 0;
        
        .image-container {
            height: 100%;
        }
        
        figure {
            margin: 0;
            height: 100%;
            
            > div {
                height: 100%;
            }
        }
        
        .text-container {
            @include backgroundBox;
        }
        
        img {
            width: 100%;
            height: 320px;
            object-fit: cover;
            //border-radius: 16px 16px 0 0;
        }
        
        @include media-breakpoint-up(lg) {
            //border-radius: 16px 0 0 16px;
            
            .row {
                min-height: 350px;
            }
            
            img {
                height: 100%;
                //border-radius: 16px 0 0 16px;
            }
        }
        
        @include media-breakpoint-up(xxl) {
            .row {
                min-height: 400px;
            }
        }
    }
    
    .ce_column > & {
        overflow-x: hidden;
        background-color: $neutralWarmGrey;
        border-radius: 16px 16px 0 0;
        
        .image-container {
            height: 100%;
        }
        
        figure {
            margin: 0;
            height: 100%;
            
            > div {
                height: 100%;
            }
        }
        
        > div {
            padding: 24px;
            background-color: $bgColorInfobox;
            
            @include media-breakpoint-only(md) {
                padding: 32px;
            }
        }
        
        img {
            width: 100%;
            height: 320px;
            object-fit: cover;
            border-radius: 16px 16px 0 0;
        }

    }
}

.content-text.text-mmz {
    position: relative;
    color: black;
    
    figure {
        margin: 0;
    }
    
    figcaption {
        display: none;
    }
    
    .image-container {
        //position: absolute;
        //z-index: 1;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: inherit;
        img {
            width: 100%;
            height: auto;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }
    }

    .text-container {
        @include backgroundBox($neutralWarmGrey);
    }
    
    &.cyan {
        .text-container {
            @include backgroundBox(map-get($box-bg-colors, 'cyan'));
        }
    }
    
    h2,
    h3,
    h4 {
        @include fs(24,26,xbold);
        
        margin-bottom: 28px;
        //flex-grow: 4;
    }
    
    p {
        @include fs(16,22,reg);
        
        margin-bottom: 0;
        
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        
        &:nth-child(3) {
            @include fs(32,35,xbold);
            
            margin-bottom: 24px;
        }
    }
    
    ul {
        display: flex;
        margin-bottom: 0;
        
        li {
            margin-bottom: 0;
        }

        li:not(:last-child) {
            margin-right: 8px;
        }
    }
    
    p > a,
    li > a {
        color: inherit;
        text-decoration: none;
        
        &[href^="mailto:"],
        &[href^="http"],
        &[href^="/"] {
            @include button('darkPetrol');
            @include buttonExt;
            
            margin-bottom: 12px;
        }
    }
    
    @include media-breakpoint-down(lg) {
        background-image: none !important;
    }
    
    @include media-breakpoint-up(lg) {
        padding: 24px;
        border-radius: 24px;
        background-position: center left;
        background-repeat: no-repeat;
        background-size: cover;
        
        .image-container {
            height: 100%;
            
            figure,
            .c-image-container {
                height: 100%;
                
                &__copyright-link {
                    bottom: 0;
                }
            }
            
            img {
                display: none;
            }
        }
        
        .text-container {
            border-radius: 16px;
            //min-height: 483px;
            
        }
    }
    
    @include media-breakpoint-up(xl) {
        .text-container {
            //min-height: 355px;
        }
        
        h2,
        h3,
        h4 {
            @include fs(32, 35, xbold);
            
            margin-bottom: 100px;
        }
        
        p {
            @include fs(20,32,reg);
            
            &:nth-child(3) {
                @include fs(40,44,xbold);
            }
        }
    }
}

.module-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 360px;
    
    &--yellow {
        @include backgroundBox(map-get($box-bg-colors, 'yellow'));
    }
    
    &--petrol {
        @include backgroundBox(map-get($box-bg-colors, 'petrol'));
        
        color: white;
    }
    
    &--orange {
        @include backgroundBox(map-get($box-bg-colors, 'orange'));
        
        color: map-get($box-fg-colors, 'orange');
    }
    
    &--red {
        @include backgroundBox(map-get($box-bg-colors, 'red'));
        
        color: map-get($box-fg-colors, 'red');
    }
    
    h2,
    h3,
    h4 {
        //@include fs(24,26,xbold);
        @include fs(32, 35, xbold);
        
        color: inherit;
    }
    
    p {
        @include fs(16,22,reg);
        
        margin-bottom: 0;
        
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
    
    ul {
        @include resetList;
        
        display: flex;
        margin-bottom: 0;
        
        li {
            margin-bottom: 0;
        }
        
        li:not(:last-child) {
            margin-right: 8px;
        }
    }
    
    p > a,
    li > a {
        &:not(.social-media) {
            @include button('darkPetrol');
            @include buttonInt;
        }

        &.social-media {
            @include buttonCircleIcon(40px, 36px);
            
            @each $name in ['instagram', 'facebook', 'x', 'community', 'tiktok'] {
                &--#{$name} {
                    &:before {
                        @include setIcon($name);
                    }
                }
            }
            //
            //&.instagram {
            //    &:before {
            //        @include setIcon('instagram');
            //    }
            //}
        }
        //margin-bottom: 12px;
    }
    
    @include media-breakpoint-up(xl) {
            //min-height: 355px;
        
        //h2,
        //h3,
        //h4 {
        //    @include fs(32, 35, xbold);
        //}
        
        //p {
        //    @include fs(20,32,reg);
        //}
    }
}

