.content-gallery {
    ul {
        @include resetList;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.content-gallery.standard {
    .thumb-swiper {
        margin-bottom: 8px;
    }
    // figcaption see _image.scss
    
    .swiper-button-next,
    .swiper-button-prev {
        background-color: white;
        color: black;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
    }
    
    .swiper-button-prev {
        @include buttonPrev;
    }
    
    .swiper-button-next {
        @include buttonNext;
    }
}

.content-gallery.gallery-vertical {
    padding: 30px 30px 0 30px;
    background-color: lightgrey;
    overflow: hidden;
    height: 500px;
    
    .swiper-slide {
        //max-height: 400px;
    }
    
    figure {
        //height: 500px;
    }
}