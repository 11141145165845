.ce_dztabs__pane {
    &.is-hidden {
        display: none;
    }
}

.ce_dztabs {
    .nav-tabs {
        align-items: flex-end;
        border: 0;
        
        .nav-item {
            
            &:not(:last-child) {
                padding-right: 40px;
            }
        }
        
        .nav-link {
            border: 0;
            margin-bottom: 0;
            
            position: relative;
            padding: 8px 0 8px 0;
            border-bottom: 1px solid black;
            text-align: left;
            
            @include fs(24, 29, semibold);
            
            color: black;
            
            &.active {
                color: $textPetrol;
                border-bottom: 1px solid $textPetrol;
            }
            
            &:not(.active) {
                padding-right: 40px;
                &:after {
                    @include addIcon('tab-closed', 30px);
                    
                    position: absolute;
                    z-index: 1;
                    bottom: 15px;
                    right: 0;
                    //width: 24px;
                    //height: 24px;
                    //overflow: hidden;
                    //border-radius: 50%;
                    //background-color: black;
                    //color: white;
                    //text-align: center;
                    //line-height: 24px;
                }
            }
        }
    }
    
    &.standard {
        .nav-item {
            //flex-basis: 0;
            //flex-grow: 1;
        }
        
        .ce_dztabs__pane-inside {
            @include backgroundBox($tabs-pane-bg);
            
            padding-bottom: 24px !important;
            
            > div:not(:last-child) {
                @include marginLG;
            }
        }
    }
    
    &.tabs-media {
        .ce_dztabs__pane-inside {
            //@include backgroundBox(white);
        }
        
        .nav-tabs {
            @include marginBottomStd;
            
            justify-content: flex-start;
            
            .nav-link {
                @include fs(36, 36, xbold);
                
                text-transform: uppercase;
                
                border-bottom: 0;
                padding-bottom: 0;
                
                &.active {
                    border-bottom: 0;
                }
                
                &:not(.active) {
                    &:after {
                        bottom: 6px;
                    }
                }
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        .nav-tabs {
            .nav-link {
                @include fsc(28, 34);
                
                &.active {
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .nav {
        display: block;
    }
}

//.responsive-tabs {
//    padding: 1rem;
//}
//
//.responsive-tabs .nav-tabs {
//    display: none;
//}
//
//@media (min-width: 768px) {
//    .responsive-tabs .nav-tabs {
//        display: flex;
//    }
//
//    .responsive-tabs .card {
//        border: none;
//    }
//
//    .responsive-tabs .card .card-header {
//        display: none;
//    }
//
//    .responsive-tabs .card .collapse {
//        display: block;
//    }
//}
//
//@media (max-width: 767px) {
//    .responsive-tabs .tab-pane {
//        display: block !important;
//        opacity: 1;
//    }
//}