.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
    bottom: 24px;
    
    @include media-breakpoint-only(md) {
        bottom: 32px;
    }
    
    @include media-breakpoint-up(lg) {
        bottom: 40px;
    }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 6px;
    
    //&-active {
    //    margin: 0 3px;
    //}
}

.swiper-pagination-bullet {
    opacity: 1;
}

.swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    transform: translateY(4px);
}

.content-bg-slider {
    position: relative;
    height: 800px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    
    .content-container {
        position: relative;
        z-index: 200;
    }
    
    .bg-slider-swiper {
        //width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
    
    .swiper-pagination-bullet {
        background-color: white;
        
    }
    
    .swiper-button-prev,
    .swiper-button-next {
        //top: auto;  // org
        //right: auto;   // org
        //bottom: -48px;  // org
        //left: 50%;  // org
        
        //top: -48px;  // test
        //left: 50%;  // test
        
        top: -48px;  // test 2
        right: 24px;  // test 2
        left: auto;  // test 2
    }
    
    .swiper-button-prev {
        @include buttonPrev;
        
        //transform: translateX(-37px); // -40 -3 +12/2  (org)
        //
        transform: translateX(-48px); // -40 -3 +12/2  (test)
    }
    
    .swiper-button-next {
        @include buttonNext;
        
        //transform: translateX(9px); // 3 +12/2  (org)
        //
        transform: translateX(0); // 3 +12/2  (test)
    }

    .teaser {
        @include backgroundBox(map-get($box-bg-colors, 'petrol'));
        @include boxSlider;
        
        height: 523px;
        border-radius: 16px;
        color: white;

        h1,
        h2,
        h3,
        h4 {
            @include fs(24,29,semibold);
            
            color: $textTurquoise;
            margin-bottom: 8px;
        }
        
        p {
            @include fs(24,27,xbold);
            
            a.inline {
                text-decoration: underline;
                color: inherit;
                
                @include inlineLink(12px, 14px);
                //
                //&:after {
                //    @include addIcon('linkExternalBold', 12px);
                //
                //    display: inline-block;
                //    margin-left: 2px;
                //    //content: 'x';
                //    //font-size: 20px;
                //    color: inherit;
                //    background-color: rgba(0,0,0,0.1);
                //    border-radius: 2px;
                //
                //    transform: translateY(-14px);
                //}
            }
        }
    }
    
    .image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //height: 100%;
        height: 100%;
        z-index: 1;
        
        figure,
        .c-image-container {
            height: 100%;
            overflow: hidden;
        }
        
        img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50%;
            //max-height: 100%;
            //width: auto;
        }
    }
    
    @include media-breakpoint-up(sm) {
        .swiper-button-prev,
        .swiper-button-next {
            right: 12px;
        }
        
        .teaser {
            height: 341px;
        }
    }
    
    @include media-breakpoint-up(md) {
        .teaser {
            height: 289px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
        padding-top: 120px;
        
        .bg-slider-swiper {
            width: 50%;
        }
        
        .swiper-button-prev,
        .swiper-button-next {
            right: 50%;
            //left: 25%;   // org
            
            //right: 50%;  // test 2
        }
        
        .teaser {
            height: 632px;  // org
            //height: 500px;  // test
            
            h1,
            h2,
            h3,
            h4 {
                @include fsc(28,34);
            }
            
            p {
                @include fsc(32,35);
                
                //hyphens: auto;  // test
            }
        }
    }
    
    //@include media-breakpoint-up(xl) {
    //    //height: 670px; // test
    //
    //    .teaser {
    //        height: 500px; // test
    //
    //        p {
    //            hyphens: none;
    //        }
    //    }
    //}

    @include media-breakpoint-up(xxl) {
        .teaser {
            height: 460px; // test
        }
    }
    
}