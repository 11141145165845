.footer {
    background-color: map-get($box-bg-colors, 'darkPetrol');
    //padding-top: 16px;
    //margin-top: 50px;
    color: white;
    
    .inside {
        padding-top: 140px;
    }
    
    .container {
        position: relative;
    }
    
    &-container {
        min-height: 82px;
        padding-bottom: 40px;
    }
    
    &-content {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        
        > p {
            @include fs(16, 22);
            
            margin-bottom: 16px;
        }
    }
    
    &-sm {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
        > p {
            @include fs(13, 13, semibold);
            
            margin-bottom: 0;
            margin-right: 12px;
            padding-top: 8px;
        }
        
        ul {
            display: flex;
            flex-wrap: wrap;
            
            li:not(:last-child) {
                margin-right: 8px;
            }
            
            .footer-link {
                @include buttonCircleIcon(29px, 29px, 'petrol');
                //@include replaceWithIconFont(29px, 29px);
                //
                //background-color: map-get($button-bg-colors, 'petrol');
                //border-radius: 50%;
                //
                //&:before {
                //    color: white;
                //}
                
                @each $name in ['instagram', 'facebook', 'x', 'community', 'tiktok'] {
                    &--#{$name} {
                        @include setLinkIcon($name);
                    }
                }
                
                &--facebook {
                    &:before {
                        //transform: translateX(1px);
                    }
                }
            }
        }
    }
    
    

    
    &-logo-bmz {
        position: absolute;
        top: -16px;
        right: 0;
        z-index: 1;
        background-color: white;
        padding: 20px 28px;
        
        &:before {
            position: absolute;
            top: 0;
            left: -16px;
            z-index: 1;
            content: '';
            width: 16px;
            height: 16px;
            opacity: 0.8;
            
            //width: 0;
            //height: 0;
            border-style: solid;
            border-width: 0 0 16px 16px;
            border-color: transparent transparent $neutralGrey transparent;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .inside {
            padding-top: 155px;
        }
        
        &-logo-bmz {
            right: auto;
            left: 40px;
        }
    }
    
    @include media-breakpoint-up(md) {
        &-logo-eg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 96px;
            height: auto;
        }
        
        &-logo-eg-large {
            display: none;
        }
        
        &-content {
            padding-left: calc(96px + 40px);
        }
    }
    @include media-breakpoint-down(md) {
        &-logo-eg-large {
            width: 240px;
            height: auto;
            margin-bottom: 40px;
        }
        
        &-logo-eg {
            display: none;
        }
    }
    
    @include media-breakpoint-up(md) {
        .inside {
            padding-top: 40px;
        }
        
        &-content {
            padding-right: 280px;
        }
    }
    
    @include media-breakpoint-up(xl) {
        &-sm {
            ul {
                flex-wrap: nowrap;
            }
        }
        
        &-content {
            padding-left: 240px;
            align-items: flex-start;
            flex-wrap: nowrap;
            
            > p {
                @include fs(16, 22);
                
                margin-bottom: 0;
                margin-right: 40px;
            }
        }
    }
}

.footer-navigation {
    @include fs(16, 22);
    
    color: white;
    padding-bottom: 8px;
    
    ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        li {
            margin-bottom: 4px;
            
            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
    
    a {
        color: inherit;
        
        &:hover {
            text-decoration: underline;
        }
    }
}