@mixin stageBg($color, $height) {
    background: linear-gradient(to bottom, map-get($stage-colors, $color) $height*1px, transparent 0%);
}

@mixin makeContainerMd() {
    max-width: 100%;
    
    @include media-breakpoint-down(lg) {
        max-width: map-get($container-max-widths, 'md');
        margin-left: auto;
        margin-right: auto;
    }
    
    @include media-breakpoint-down(md) {
        max-width: map-get($container-max-widths, 'sm');
    }
    
    @include media-breakpoint-down(sm) {
        max-width: map-get($container-max-widths, 'xs');
    }
    
}