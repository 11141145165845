.teaser-link-arrow {
    @include teaserLinkArrow;
}

.teaser-link-play {
    text-align: right;
    //position: relative;
    margin-bottom: 0;
    
    a {
        &:before {
            display: inline-block;
            background-image: url('#{$image-path}/play-petrol.svg');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
            width: 56px;
            height: 56px;
            content: '';
            position: absolute;
            bottom: 24px;
            right: 24px;
            z-index: 2;
        }
        
        &:after {
            position: absolute;
            inset: 0;
            content: '';
            cursor: pointer;
        }
    }
}

.content-hyperlink.hyperlink-back {
    a {
        @include button('darkPetrol');
        @include buttonBack;
    }
}

.content-hyperlink.button-cta {
    a {
        @include button('yellow');
        @include buttonInt;
        
        max-height: 1000px;
    }
}

.content-hyperlink.button-more {
    text-align: right;
    margin-top: -32px;
    
    a {
        @include button-transparent-thin;
    }
}