.content-scroll-counter {
    padding: 24px;
    
    &.petrol {
        background-color: #CCE5E9;
    }
    
    &.grey {
        background-color: #F8F6F0;
    }
    
    .scroll-counter__row {
        > div {
            padding: 40px;
            border-bottom: 1px solid black;
            
            p {
                @include fs(24, 29, semibold);
                
                text-align: center;
            }
            
            span {
                @include fs(80, 80, xbold);
                
                display: block;
                margin-bottom: 24px;
                
                &.petrol {
                    color: $textPetrol;
                }
            }
            
            
        }
        
        &:not(:last-child) {
            @include marginSM;
        }
        
        
        @include media-breakpoint-down(lg) {
            &:last-child {
                > div {
                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
    
    
    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
        
        .scroll-counter__row {
            display: flex;
            
            > div {
                width: 33.333%;
                border-bottom: 0;
                
                &:not(:last-child) {
                    border-right: 1px solid black;
                }
            }
        }
    }
}