.content-text {
    ul,
    ol {
        @include resetList;
        
        > li {
            &:not(:last-child) {
                @include marginXS;
            }
        }
    }
    
    p,
    ul,
    ol {
        margin: 0;
        
        &:not(:last-child) {
            @include marginSM;
        }
    }
    
    //& > :last-child {
    //    margin-bottom: 0;
    //}
    
    
    //&:not(:nth-last-child(1 of p, ul, ol)) {
    //    @include marginSM;
    //}
    
    &:not(.text-button-list):not(.text-mmz) {
        p > a:not(.inline),
        li > a:not(.inline) {
            @include linkStandard;
        }
    }
    
    p > a.inline,
    li > a.inline {
        text-decoration: underline;
        color: inherit;
        
        @include inlineLink(10px, 8px);
        
        //&:after {
        //    display: inline-block;
        //    content: 'x';
        //    font-size: 12px;
        //
        //    transform: translateY(-10px);
        //}
    }
    
    
    ol {
        counter-reset: list-counter;
        
        > li {
            @include paragraphStandard;
            
            padding-left: 36px;
            position: relative;
            counter-increment: list-counter;
            
            &:before {
                @include fst(semibold);
                
                content: counter(list-counter) ". ";
                display: block;
                position: absolute;
                top: 0;
                left: 8px;
                color: $textPetrol;
            }
        }
    }
}

.mod_article > .inside,
.layout_full,
.group-infobox {
    > .content-text.standard {
        p {
            @include paragraphStandard;
        }
    }
}

.content-text.standard,
.content-text.text-list {
    p {
        @include paragraphStandard;
        
        strong {
            font-weight: 600;
        }
        
        i {
            font-style: italic;
        }
    }
}

.image-set .content-text.standard {
    @include paragraphStandard;
    
    margin-right: 24px;
}

//.content-text:not(.text-list) {
//
//}

.content-text.text-list {
    p {
        @include paragraphStandard;
    }
    
    ul {
        > li {
            @include paragraphStandard;
            
            padding-left: 36px;
            position: relative;
            
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                top: 12px;
                left: 12px;
                background-color: $textPetrol;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }
    }
}

.content-text.text-intro {
    
    p {
        @include fs(22, 33, semibold);
        
        hyphens: auto;
        padding-left: 24px;
        border-left: 1px solid;
        border-color: inherit;
        
        strong {
            color: $textPetrol;
        }
        
        &:not(:last-child) {
            @include marginLG;
        }
    }
    
    @include media-breakpoint-up(lg) {
        p {
            @include fsc(28, 42);
        }
    }
}

.content-text.text-claim {
    padding-left: 24px;
    border-left: 1px solid black;
    color: black;
    
    p {
        @include fs(24, 27, xbold);
    }
    
    @include media-breakpoint-up(lg) {
        p {
            @include fs(32, 35, xbold);
        }
    }
    
    &.petrol {
        color: $textPetrol;
        border-left-color: $textPetrol;
    }
    
    &.red {
        color: $textRed;
        border-left-color: $textRed;
    }
    
    &.blue {
        color: $textBlue;
        border-left-color: $textBlue;
    }
    
    &.column {
        @include columnBox;
        
        .rte {
            padding-left: 24px;
            border-left: 1px solid white;
        }
    }
}

.content-text.text-fact,
.content-text.text-fact-flip,
.content-text.text-fact-headline {
    min-height: 400px;
    
    @include media-breakpoint-up(lg) {
        min-height: 440px;
    }
}

.content-text.text-fact,
.content-text.text-fact-flip {
    padding: 24px;
    border-radius: 16px;
    background-color: #F1953A;
    height: 100%;
    
    h2,
    h3,
    h4 {
        @include fs(16, 16, bold);
        
        text-transform: uppercase;
        
        &:before {
            content: '';
            z-index: 1;
        }
    }
    
    &.ask {
        h2,
        h3,
        h4 {
            &:before {
                @include addIcon('frag-dich', 20px);
            }
        }
    }
    
    &.question {
        h2,
        h3,
        h4 {
            &:before {
                @include addIcon('wusstest-du', 20px);
            }
        }
    }
    
    &.todo {
        h2,
        h3,
        h4 {
            &:before {
                @include addIcon('to-do', 20px);
            }
        }
    }
    
    
    p {
        @include fs(18, 25, bold);
        
        hyphens: auto;
        
        strong {
            @include fs(32, 35, xbold);
        }
    }
    
    @include media-breakpoint-up(lg) {
        p {
            @include fsc(20, 26);
        }
    }
    
    //perspective: 1500px;
    
    //&.fact-new {
    //    perspective: 1500px;
    //    padding: 0;
    //    background-color: transparent;
    //
    //    .front {
    //        cursor: pointer;
    //
    //        h3 {
    //            @include fs(36, 36, xbold);
    //
    //            padding: 0 24px;
    //            text-align: center;
    //
    //            &:before {
    //                @include setIconSize(140px);
    //
    //                display: block;
    //                position: relative;
    //                margin-bottom: 30px;
    //            }
    //        }
    //    }
    //
    //    .headline {
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        width: 100%;
    //        height: 100%;
    //        display: flex;
    //        flex-direction: column;
    //        justify-content: center;
    //    }
    //
    //
    //    &.media {
    //        .front {
    //            h3 {
    //                color: white;
    //
    //                &:before {
    //                    @include setIconSize(80px);
    //
    //                    margin-bottom: 15px;
    //                }
    //            }
    //        }
    //    }
    //}
    
    
}

.content-text.text-fact {
    h2,
    h3,
    h4 {
        @include fs(16, 16, bold);
        
        margin-bottom: 13px;
        text-transform: uppercase;
        padding-left: 24px;
        position: relative;
        
        &:before {
            display: inline-block;
            position: absolute;
            top: -3px;
            left: 0;
        }
    }
}

.content-text.text-fact-flip {
    perspective: 1500px;
    padding: 0;
    background-color: transparent;
    
    .front {
        cursor: pointer;
        
        h2,
        h3,
        h4 {
            @include fs(36, 36, xbold);
            
            padding: 0 24px;
            text-align: center;
            
            &:before {
                @include setIconSize(140px);
                
                display: block;
                position: relative;
                margin-bottom: 30px;
            }
        }
    }
    
    .back {
        h2,
        h3,
        h4 {
            @include fs(16, 16, bold);
            
            margin-bottom: 13px;
            text-transform: uppercase;
            padding-left: 24px;
            position: relative;
            
            &:before {
                display: inline-block;
                position: absolute;
                top: -3px;
                left: 0;
            }
        }
    }
    
    .headline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    
    &.media {
        .front {
            h3 {
                color: white;
                
                &:before {
                    @include setIconSize(80px);
                    
                    margin-bottom: 15px;
                }
            }
        }
    }
    
    .flip-container {
        transition: .5s /* Die Drehung soll 0,5 Sekunden dauern */
    ;
        transform-style: preserve-3d; /* Kind-Elemente  (.front + .back) sollen ihre 3D-Eigenschaften behalten*/
        position: relative;
        height: 100%;
    }
    
    //&:hover .flip-container,
    .flip-container.open {
        transform: rotateY(180deg); /* Beim Mouse-Over wird die Szene umgedreht */
    }
    
    .front,
    .back {
        backface-visibility: hidden /* Die Rückseite von Elementen soll unsichtbar sein */
    ;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    
    .front {
        //z-index: 2;
        background-color: #F1953A;
        
        
        .bg-image {
            height: 100%;
            
            figure {
                height: 100%;
                overflow: hidden;
                
                > div {
                    height: inherit;
                }
            }
            
            img {
                object-fit: cover;
                object-position: bottom center;
                height: 100%;
                //width: 100%;
                max-width: 100%;
                border-radius: 16px;
            }
        }
    }
    
    .back {
        transform: rotateY(180deg) /* Die Rückseite des Logos wird umgedreht, um dann bei der Drehung der gesamten Szene vorne zu sein :) */
    ;
        z-index: 1;
        padding: 24px;
        background-color: #F1953A;
    }
    
}

.content-text.text-fact-headline {
    padding: 24px;
    border-radius: 16px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    
    img {
        display: none;
    }
    
    h2,
    h3,
    h4 {
        @include fs(56, 50, xbold);
        
        color: white;
        
        @include media-breakpoint-up(lg) {
            @include fsc(72, 65);
        }
    }
}

.content-text.text-button-list {
    h2,
    h3,
    h4 {
        @include fs(24, 29, semibold);
        
        margin-bottom: 16px;
    }
    
    li {
        display: block;
        margin-bottom: 8px !important;
    }
    
    a {
        @include fs(28, 25, xbold);
        
        text-transform: uppercase;
        display: inline-block;
        border-radius: 40px;
        padding: 3px 6px 3px 12px;
        background-color: map-get($box-bg-colors, 'orange');
        color: black;
        text-decoration: none;
        
        &:after {
            @include addIcon('arrow-button-thema', 16px);
            
            font-size: 20px;
            padding-left: 6px;
        }
        
        &:hover {
            text-decoration: underline;
        }
    }
    
    @include media-breakpoint-only(md) {
        li {
            display: inline-block;
            margin-right: 8px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fs(28, 34, semibold);
        }
    }
}