@mixin maskSlider {
    .mask-container {
        overflow-x: hidden;
    }
    
    .navigation {
        position: relative;
    }
    //-webkit-mask-image: linear-gradient( to right,transparent,
    //    black 20%,
    //    black 80%,
    //    transparent 100%);
    
    .container {
        position: relative;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
        top: -72px;
        right: 0;
        bottom: auto;
        left: auto;
        
        //@include media-breakpoint-up(md) {
        //    top: 130px;
        //
        //    &.swiper-button-disabled {
        //        display: none;
        //    }
        //}
        
        @include media-breakpoint-up(lg) {
            top: 80px;
            
            &.swiper-button-disabled {
                display: none;
            }
        }
        
        @include media-breakpoint-up(xl) {
            top: 100px;
        }
        
        @include media-breakpoint-up(xxl) {
            top: 130px;
        }
    }
    
    .swiper-button-prev {
        @include buttonPrev;

        right: 58px;  // org
        
        @include media-breakpoint-up(lg) {
            left: calc((-1 * (100vw - 100%)/2) + 40px);
        }
    }
    
    .swiper-button-next {
        @include buttonNext;
        
        right: 12px;  // org

        @include media-breakpoint-up(lg) {
            right: calc((-1 * (100vw - 100%)/2) + 40px);
        }
        
        //background-color: white;
        //opacity: 0.8;
        //box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.27);
        
        //&:after {
        //    transform: translateX(2px);
        //}
    }
    
    .swiper-slide {
        height: auto;
        align-self: stretch;
        //width: 440px;
    }
    
    @include media-breakpoint-up(md) {
        .mask-container {
            mask-image: linear-gradient(to right, transparent,
                black 5%,
                black 95%,
                transparent 100%);
        }
    }
}

.content-element-group.group-slider-standard,
.content-project-teaser-element {
    @include maskSlider;
    
    &.hide-mask {
        .mask-container {
            mask-image: none !important;
        }
    }
}

.content-project-teaser-element {
    .swiper-button-prev,
    .swiper-button-next {
        @include media-breakpoint-up(lg) {
            top: 80px;
        }
        
        @include media-breakpoint-up(xl) {
            top: 100px;
        }
        
        @include media-breakpoint-up(xxl) {
            top: 130px;
        }
    }
}

.content-element-group.group-slider-standard {
    .mask-container {
        //overflow-x: visible;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
        //top: -48px;
        
        @include media-breakpoint-up(lg) {
            transform: translateY(-50%);
            top: 150px;
        }
        
        @include media-breakpoint-up(xl) {
            top: 160px;
        }
        
        @include media-breakpoint-up(xxl) {
            top: 190px;
        }
    }
}
