.video-container {
    position: relative;
    padding-bottom: 56.25%;
    //padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content-youtube,
.content-media {
    .video-container {

        
        button {
            @include addLargePlayButton;
            
            border: 0;
            
            position: relative;
            z-index: 2;
            
            img {
                z-index: 1;
            }
        }
    }
}

.content-youtube {
    .row {
        align-items: flex-end;
    }
    
    .video-container {
        @include imageStandardNoCaption;
    }
    
    iframe {
        width: 100%;
    }
    
    figcaption {
        @include fs(15,18,reg);
        
        margin-top: 8px;
        padding-left: 16px;
        border-left: 1px solid $textBlack;
    }
    
    @include media-breakpoint-up(lg) {
        figcaption {
            padding-left: 24px;
        }
    }
}