.media-teaser {
    @include imageStandardNoCaption;
    
    //height: 100%;
    color: black;
    
    .inside {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .content-container {
        height: auto;
        position: relative;
        flex-grow: 2;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        padding: 24px;
        background-color: map-get($box-bg-colors, 'grey');
    }
    
    h2,
    h3,
    h4 {
        @include fs(20, 22, semibold);
        
        margin-bottom: 6px;
        flex-grow: 2;
        hyphens: auto;
    }
    
    .text {
        margin-bottom: 16px;
        
        p {
            @include fs(20, 22, semibold);
        }
    }
    
    .meta {
        @include fs(15, 18);
        
        margin-bottom: 6px;
    }
    
    //p.link {
    //    text-align: right;
    //    @include fs(24, 29);
    //
    //    a {
    //        &:before {
    //            content: '>';
    //            display: inline-block;
    //            text-decoration: none;
    //            font-size: 20px;
    //            font-weight: 700;
    //            color: black;
    //        }
    //
    //        &:after {
    //            position: absolute;
    //            inset: 0;
    //            content: '';
    //        }
    //    }
    //}
    
    &--vid {
        .content-container {
            min-height: 280px;
        }
        
        .teaser-link-play {
            height: 80px;
        }
    }
    
    &--pod {
        .content-container {
            min-height: 280px;
        }
    }
    
    &--doc {
        .content-container {
            background-color: map-get($box-bg-colors, 'lightCyan');
        }
        
        .filemeta {
            display: block;
            margin-top: 6px;
            
            @include fs(15, 18, ita);
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fsc(24, 25);
        }
        
        .text p {
            @include fs(24, 29);
        }
        
        
    }
}

.media-teasers {
    @include marginLG;
    @include gridVerticalGutter;
    
    .link-container {
        text-align: right;
        margin-top: 24px;
        
        .button-transparent-thin {
            color: white;
            border-color: white;
        }
    }
    
    .media-teaser {
        &--vid.teaser-1 {
            @include media-breakpoint-up(lg) {
                .inside {
                    flex-direction: row;
                }
                
                figure {
                    width: 66.666%;
                }
                
                .content-container {
                    width: 33.333%;
                }
            }
            
            @include media-breakpoint-up(xl) {
                figure {
                    width: 75%;
                }
                
                .content-container {
                    width: 25%;
                }
            }
        }
        
        &--pub {
            @include media-breakpoint-up(lg) {
                &.teaser-1,
                &.teaser-2 {
                    .inside {
                        flex-direction: row;
                    }
                    
                    figure {
                        height: 460px;
                        width: 50%;
                        
                        img {
                            //width: 100%;
                            height: 460px;
                            object-fit: cover;
                        }
                    }
                    
                    .content-container {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.media-list {
    @include marginLG;
    
    @include media-breakpoint-up(md) {
        .media-list-container {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            margin-left: -12px;
            margin-right: -12px;
            
            > div {
                width: 50%;
                padding: 12px;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        &:not(.media-list--doc) {
            .media-list-container {
                > div {
                    width: 33.333%;
                    
                    .inside {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.media-teasers,
.media-selection,
.content-media {
    .media-teaser {
        height: 100%;
    }
}

.media-list {
    .media-teaser {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.media-filters {
    @include marginBottomStd;
    
    button {
        @include button('yellow');
        
        white-space: nowrap;
        margin-top: 12px;
    }
}

.media-reader {
    .media-reader-image {
        @include marginLG;
    }
    
    &--pub {
        @include imageStandardNoCaption;
    }
    
    .podcast-container {
        @include marginLG;
    }
}

.media-selection {
    .link-container {
        text-align: right;
        margin-top: 24px;
    }
}

.media-selection,
.content-media {
    //&--doc {
        @include gridVerticalGutter;
    //}
    
    &--vid {
        &.media-selection--1 {
            @include media-breakpoint-up(lg) {
                .media-teaser {
                    .inside {
                        flex-direction: row;
                    }
                    
                    figure {
                        width: 66.666%;
                    }
                    
                    .content-container {
                        width: 33.333%;
                    }
                }
            }
            
            @include media-breakpoint-up(xl) {
                .media-teaser {
                    .inside {
                        flex-direction: row;
                    }
                    
                    figure {
                        width: 75%;
                    }
                    
                    .content-container {
                        width: 25%;
                    }
                }
            }
        }
    }
    
    &--pub {
        &.media-selection--1,
        &.media-selection--2 {
            @include media-breakpoint-up(lg) {
                .media-teaser {
                    .inside {
                        flex-direction: row;
                    }
                    
                    figure {
                        height: 460px;
                        width: 50%;
                        
                        img {
                            //width: 100%;
                            height: 460px;
                            object-fit: cover;
                        }
                    }
                    
                    .content-container {
                        width: 50%;
                    }
                }
            }
        }
    }
    
    &--pod {
        &.media-selection--1 {
            @include media-breakpoint-up(lg) {
                .media-teaser {
                    .inside {
                        flex-direction: row;
                    }
                    
                    figure {
                        width: 66.666%;
                    }
                }
            }
        }
    }
}

.content-media,
.media-selection--vid {
    .modal-dialog {
        
        
        .video-container {
            padding-top: 0;
            
            button {
                border: 0;
            }
        }
        
        .video-caption {
            @include backgroundBox(white);
            
            .modal-caption-headline {
                @include fs(24, 29, semibold);
                
                margin-bottom: 8px;
            }
            
            p {
                @include fs(18, 27);
                
                margin-bottom: 0;
            }
        }
        
        iframe {
            border: 0;
        }
    }
    
    &--link {
        a {
            @include linkStandard;
        }
    }
}

.media-linklist {
    .link-container {
        text-align: right;
    }
}