.content-table {
    &.standard,
    &.full-width {
        h2,
        h3,
        h4 {
            @include fs(24,26,xbold);
            
            color: white;
            padding: 20px;
            background-color: $table-headline-bg;
            margin-bottom: 0;
        }
        
        @include media-breakpoint-down(md) {
            .table-container {
                width: 100%;
                overflow-y: hidden;
                overflow-x: scroll;
            }
            
            table {
                min-width: 800px;
            }
        }
        
        @include media-breakpoint-up(lg) {
            @include fsc(32,35);
        }
        
        table {
            border-spacing: 2px;
            border-collapse: separate;
            margin: -2px;
            width: 100%;
        }
        
        th,
        td {
            padding: 20px;
            
            &:not(:last-child) {
                margin-right: 1px;
            }
        }
        
        th {
            @include fs(20,22,semibold);
            
            background-color: $table-header-bg;
            color: $table-header-color;
        }
        
        td {
            @include fs(16, 22, reg);
            
            border-bottom: 1px solid black;
        }
    }


    &.table-row {
        tr {
            border-top: 1px solid $textBlack;
            
            &:last-child {
                border-bottom: 1px solid $textBlack;
            }
        }
        
        th,
        td {
            padding: 24px 24px 24px 0;
            vertical-align: top;
        }
        
        td {
            @include fs(16, 22, reg);
            
            &:last-child {
                padding-right: 0;
            }
        }
        
        th {
            @include fs(24, 29, semibold);
            
            color: $textPetrol;
        }
        
        @include media-breakpoint-up(lg) {
            th {
                @include fsc(28, 34);
            }
            
            td {
                @include fsc(20, 32);
            }
        }
        
        @include media-breakpoint-down(md) {
            table,
            tr,
            th,
            td {
                display: block;
            }
            
            td {
                padding-top: 0;
            }
            
            th {
                padding-bottom: 8px;
            }
        }
    }

}


.content-table.table-meta,
.mod_eventreader .header-table,
.mod_online_registration .header-table {
    @include backgroundBox(map-get($box-bg-colors, 'lightCyan'));
    
    th {
        @include fs(15,18);
        
        padding-right: 16px;
        white-space: nowrap;
        vertical-align: top;
    }
    
    td {
        @include fs(15,18,semibold);
        
        width: 100%;
        vertical-align: top;
    }
    
    tr {
        &:not(:last-child) {
            th,
            td {
                padding-bottom: 8px;
            }
        }
    }
}