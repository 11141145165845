.content-headline:not(.headline-color):not(.headline-color-rev):not(.headline-landingpage),
.content-text {
    h2 {
        @include headlineLevel2;
        @include marginHeadlineStandard;
    }
    
    h3 {
        @include headlineLevel3;
        @include marginHeadlineStandard;
    }
    
    h4 {
        @include headlineLevel4;
        @include marginHeadlineStandard;
    }
    
    h5 {
        @include headlineLevel5;
        @include marginHeadlineStandard;
    }
    
    .content-accordion &,
    .ce_dztabs & {
        h2,
        h3,
        h4 {
            @include headlineLevel4;
            @include marginHeadlineStandard;
        }
    }
}

.content-headline.headline-color,
.content-headline.headline-color-rev {
    @include marginHeadline;

    h1,
    h2,
    h3,
    h4 {
        @include fs(56,50,xbold);
        
        @include media-breakpoint-up(lg) {
            @include fsc(72,65);
        }
    }
}

.content-headline.headline-color {
    h1,
    h2,
    h3,
    h4  {
        color: black;
        
        > span {
            color: $textPetrol;
        }
    }
    
    .mod_article.dark &,
    .mod_article.petrol & {
        h1,
        h2,
        h3,
        h4  {
            color: white;
            
            > span {
                color: $textTurquoise;
            }
        }
    }
}

.content-headline.headline-color-rev {
    h1,
    h2,
    h3,
    h4  {
        color: $textPetrol;
        
        > span {
            color: black;
        }
    }
    
    .dark &,
    .petrol & {
        h1,
        h2,
        h3,
        h4  {
            color: $textTurquoise;
            
            > span {
                color: white;
            }
        }
    }
}

.content-headline.headline-landingpage,
.indent-headline-container {
    @include marginHeadline;
    
    h1,
    h2,
    h3,
    h4 {
        @include fs(28,28,xbold);
    
        padding-right: 100px;
        text-transform: uppercase;
        color: inherit;
        
        @include media-breakpoint-up(lg) {
            @include fsc(36,36);
        }
    }
}

.stage-container,
#stage .content-headline,
#stage .mod_newsreader {
    h1 {
        @include headlineLevel1;
        
        color: inherit;
    }
}

.h1-hyphen .stage-container h1 {
    hyphens: auto;
}