.c-image-container {
    position: relative;
    
    &__copyright-link {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        
        @include replaceWithIcon(23px, 23px, copyright);
        
        .b {
            display: none;
            font-family: 'Arial', sans-serif;
            font-weight: 500;
        }
        
        &:before {
            font-family: 'Arial', sans-serif;
            font-weight: 500;
        }
        
        &:focus-visible {
            &:before {
                border: 1px solid white;
            }
        }
    }
}

.ce_dzteaser--image-link {
    .c-image-container {
        &__copyright-link {
            top: 10px;
        }
    }
}

.tippy-box[data-theme~='standard'] {
    border-radius: 0;
    transform: translate(-23px, 10px);
    background-color: black;
    
    .tippy-content {
        @include fs(15,18);
    
        padding: 15px;
        max-width: 237px;
    }
}
