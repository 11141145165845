.button-transparent-thin {
    @include button-transparent-thin;
}

.button-darkpetrol {
    @include button('darkPetrol');
}

.button-grey {
    @include button('grey');
}

.button-darkpetrol-with-state {
    @include button('darkPetrol');
    
    &:not(.active) {
        background-color: grey;
        
        &:hover {
            text-decoration: none;
        }
    }
}

.button-int {
    @include buttonInt;
}

.button-download {
    @include button('cyan');
}

.button-back {
    @include button('darkPetrol');
    @include buttonBack;
    
}

.label {
    @include label;
    
    &.label--petrol-light {
        background-color: $petrolLight;
        color: black;
    }
}
