.pagination {
    width: 100%;
    
    p {
        @include fs(20,32,bold);
        
        margin-bottom: 8px;
    }
    
    ul {
        @include resetList;
        
        display: flex;
        
        li {
            margin-right: 4px;
            
            a,
            span,
            strong {
                @include fs(20,40,bold);
                
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                text-align: center;
                text-decoration: none;
            }
            
            &:not(.previous):not(.next) {
                a {
                    background-color: $petrolLight;
                    color: black;
                }
                
                strong {
                    background-color: $textPetrol;
                    color: white;
                }
            }
            
            > .previous,
            > .next {
                font-size: 0;
                
                &:after {
                    line-height: 40px;
                }
            }
            
            //&.previous,
            //&.next {
            //    a,
            //    span {
            //        @include replaceWithIconFont(40px, 40px);
            //
            //        background-color: $neutralWarmGrey;
            //
            //        &:before {
            //            color: black;
            //        }
            //    }
            //
            //    span {
            //        &:before {
            //            color: $neutralGrey;
            //        }
            //    }
            //}
            
            > .previous {
                @include buttonPrev;
                
                &:after {
                    line-height: 40px;
                }
                margin-left: 24px;
                
                //a,
                //span {
                //    &:before {
                //        @include fs(20,40,bold);
                //
                //        content: '<';
                //    }
                //}
            }
            
            > .next {
                @include buttonNext;
                
                &:after {
                    line-height: 40px;
                }

                //a,
                //span {
                //    &:before {
                //        @include fs(20,40,bold);
                //
                //        content: '>';
                //    }
                //}
            }
        }
    }
    
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        
        p {
            flex-grow: 2;
            margin-bottom: 0;
        }
    }
}