.main {
    > .mod_article:first-child {
        padding-top: 80px;
    }
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    @include media-breakpoint-down(sm) {
        padding-right: 24px;
        padding-left: 24px;
    }
}


//@include media-breakpoint-down(xl) {
//    .col-md-6.col-xl-3:first-child,
//    .col-md-6.col-xl-3:nth-child(2) {
//        margin-bottom: 24px;
//    }
//}
//
//@include media-breakpoint-down(md) {
//    .col-md-6.col-xl-3:not(:last-child) {
//        margin-bottom: 24px;
//    }
//}
//
//@include media-breakpoint-down(lg) {
//    .col-lg-3:not(:last-child) {
//        margin-bottom: 24px;
//    }
//}