@mixin gridVerticalGutter() {
    .row {
        margin-bottom: -24px;
        
        > div {
            margin-bottom: 24px;
        }
    }
}

.ce_row {
    &.standard {
        overflow: hidden;
        
        > .row {
            margin-bottom: -24px;
            
            > .ce_column {
                margin-bottom: 24px;
            }
        }
    }
    
    &.margin-grid {
        margin-bottom: 24px;
    }
    
    &.cols-2 {
        @include media-breakpoint-down(lg) {
            .ce_column:not(:last-child) {
                margin-bottom: 32px;
            }
        }
    }
    
    &.margin-outside {
        margin-right: 24px;
        margin-left: 24px;
    }
    
    &.margin-bottom {
        @include marginLG;
    }
    
    &.no-gutters {
        .row {
            margin-bottom: 0;
            
            > .ce_column {
                margin-bottom: 0;
            }
        }
    }
    
    &.overflow {
        overflow: visible;
    }
}

.row {
    &.align-bottom {
        align-items: flex-end;
    }
}