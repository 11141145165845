// honeypot
.ce_form .align-left {
    position: absolute;
    left: -1000px;
    top: 0;
    z-index: 1;
}

.ce_form {
    &.margin-bottom {
        @include marginBottomStd;
    }
    
    .widget {
        @include marginXS;
        
        p.error {
            display: none;
        }
        
        > label,
        fieldset > legend {
            @include fs(14, 16, reg);
            
            display: block;
            margin-bottom: 2px;
            
            &.label {
                color: $form-error-color;
            }
        }
        
        input[type='text'],
        input[type='search'],
        input[type='email'],
        input[type='password'],
        textarea {
            @include fs(16, 16, reg);
            
            width: 100%;
            padding: 11px 16px 10px 16px;
            background-color: $form-widget-bg;
            border: 0;
            border-radius: 4px;
            //padding: 3px;
            
            &.error {
                border: 1px solid $form-error-color;
            }
        }
        
        select {
            @include fs(16, 16, reg);
            
            border: 0;
            border-radius: 4px;
            width: 100%;
            padding: 10px 16px 9px 16px;
            background-color: $form-select-bg;
            
            option {
                @include fs(16, 16, reg);
            }
            
            //appearance: none;
            //position: relative;
            //
            //&:after {
            //    @include addIcon('dropdown-closed', 16px);
            //
            //    position: absolute;
            //    top: 0;
            //    right: 0;
            //    z-index: 1;
            //    display: block;
            //    width: 20px;
            //    height: 20px;
            //}
            
            &.error {
                border: 1px solid $form-error-color;
            }
        }
        
        
        button[type="submit"] {
            @include button();
            
            &[disabled] {
                opacity: 0.5;
            }
        }
    }
    
    .widget-explanation {
        p > a {
            color: black;
            text-decoration: underline;
        }
    }
    
    .meta {
        @include marginXS;
        
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
    
    .pretext,
    .posttext {
        @include fs(18, 27);
    }
}

.checkbox_container,
.radio_container {
    input {
        visibility: hidden;
        display: none;
    }
    
    label:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 24px;
        height: 24px;
        border: 1px solid black;
        border-radius: 3px;
        z-index: 99999;
    }
    
    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
    }
    
    label {
        @include fs(20, 32, reg);
        
        position: relative;
        padding-left: 36px;
    }
    
    &.error {
        label {
            color: $form-error-color;
            
            &:before {
                border: 1px solid $form-error-color;
            }
        }
    }
    
    
}

.checkbox_container {
    > span {
        display: block;
    }
    
    label:before {
        border-radius: 3px;
    }
    
    input:checked + label:after {
        left: 8px;
        top: 5px;
        width: 8px;
        height: 16px;
        border: solid #000000;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

.radio_container {
    > span {
        display: block;
    }
    
    label:before {
        border-radius: 50%;
    }
    
    input:checked + label:after {
        left: 6px;
        top: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: black;
    }
}


.form-mandatory {
    @include marginBottomStd;
}