//@font-face {
//  font-family: 'icomoon';
//  src:  url('#{$font-path}/icomoon.eot?6ledk5');
//  src:  url('#{$font-path}/icomoon.eot?6ledk5#iefix') format('embedded-opentype'),
//    url('#{$font-path}/icomoon.ttf?6ledk5') format('truetype'),
//    url('#{$font-path}/icomoon.woff?6ledk5') format('woff'),
//    url('#{$font-path}/icomoon.svg?6ledk5#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//  font-display: block;
//}

//@font-face {
//  font-family: 'icomoon';
//  src:  url('#{$font-path}/icomoon.eot?htzep');
//  src:  url('#{$font-path}/icomoon.eot?htzep#iefix') format('embedded-opentype'),
//  url('#{$font-path}/icomoon.ttf?htzep') format('truetype'),
//  url('#{$font-path}/icomoon.woff?htzep') format('woff'),
//  url('#{$font-path}/icomoon.svg?htzep#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//  font-display: block;
//}

@font-face {
  font-family: 'iconfont';
  src:  url('#{$font-path}/iconfont.eot?htzep');
  src:  url('#{$font-path}/iconfont.eot?htzep#iefix') format('embedded-opentype'),
  url('#{$font-path}/iconfont.ttf?htzep') format('truetype'),
  url('#{$font-path}/iconfont.woff?htzep') format('woff'),
  url('#{$font-path}/iconfont.svg?htzep#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"], [class*=" _icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//[class^="icon-"], [class*=" icon-"] {
//  /* use !important to prevent issues with browser extensions that change fonts */
//  font-family: 'icomoon' !important;
//  speak: never;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//
//  /* Better Font Rendering =========== */
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

._icon-accordion-closed:before {
  content: "\e900";
}
._icon-accordion-opened:before {
  content: "\e901";
}
._icon-arrow-breadcrumb:before {
  content: "\e902";
}
._icon-arrow-button-thema:before {
  content: "\e903";
}
._icon-arrow-menu-closed:before {
  content: "\e904";
}
._icon-arrow-menu-opened:before {
  content: "\e905";
}
._icon-arrow-teaser:before {
  content: "\e906";
}
._icon-arrowShowAll:before {
  content: "\e907";
}
._icon-close:before {
  content: "\e908";
}
._icon-community:before {
  content: "\e909";
}
._icon-consent-active .path1:before {
  content: "\e90a";
  color: rgb(229, 242, 244);
}
._icon-consent-active .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 66, 74);
}
._icon-consent-inactive .path1:before {
  content: "\e90c";
  color: rgb(229, 242, 244);
}
._icon-consent-inactive .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(0, 124, 146);
}
._icon-contrast:before {
  content: "\e90e";
}
._icon-dropdown-closed:before {
  content: "\e90f";
}
._icon-dropdown-opened:before {
  content: "\e910";
}
._icon-facebook:before {
  content: "\e911";
}
._icon-filter-add:before {
  content: "\e912";
}
._icon-filter-close:before {
  content: "\e913";
}
._icon-filter-plus:before {
  content: "\e914";
}
._icon-filter-substract:before {
  content: "\e915";
}
._icon-form-checkmark:before {
  content: "\e916";
}
._icon-frag-dich:before {
  content: "\e917";
}
._icon-gebaerdensprache:before {
  content: "\e918";
}
._icon-instagram:before {
  content: "\e919";
}
._icon-language-de:before {
  content: "\e91a";
}
._icon-language-en:before {
  content: "\e91b";
}
._icon-leichte-sprache:before {
  content: "\e91c";
}
._icon-linkAnchorBold:before {
  content: "\e91d";
}
._icon-linkBackCTA:before {
  content: "\e91e";
}
._icon-linkCTA:before {
  content: "\e91f";
}
._icon-linkDownloadBold:before {
  content: "\e920";
}
._icon-linkDownloadCTA:before {
  content: "\e921";
}
._icon-linkExternalBold:before {
  content: "\e922";
}
._icon-linkExternalCTA:before {
  content: "\e923";
}
._icon-linkMailToBold:before {
  content: "\e924";
}
._icon-linkMailtoCTA:before {
  content: "\e925";
}
._icon-linkStandardBold:before {
  content: "\e926";
}
._icon-mediawall-blog:before {
  content: "\e927";
}
._icon-menu:before {
  content: "\e928";
}
._icon-merkzettel-checkmark:before {
  content: "\e929";
}
._icon-merkzettel-minus:before {
  content: "\e92a";
}
._icon-merkzettel-plus:before {
  content: "\e92b";
}
._icon-overview:before {
  content: "\e92c";
}
._icon-play-default .path1:before {
  content: "\e92d";
  color: rgb(255, 255, 255);
}
._icon-play-default .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 66, 74);
}
._icon-play-hover .path1:before {
  content: "\e92f";
  color: rgb(255, 255, 255);
}
._icon-play-hover .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 43, 48);
}
._icon-quote:before {
  content: "\e931";
}
._icon-search-menu:before {
  content: "\e932";
}
._icon-slider-left:before {
  content: "\e933";
}
._icon-slider-right:before {
  content: "\e934";
}
._icon-tab-closed:before {
  content: "\e935";
}
._icon-to-do:before {
  content: "\e936";
}
._icon-wusstest-du:before {
  content: "\e937";
}
._icon-x:before {
  content: "\e938";
}
