.eventtip-teaser,
.event-teaser {
    @include imageStandardNoCaption;
    
    &:not(:last-child) {
            @include marginSM;
        }
    
    
    .date {
        @include fs(32,32);
    }
    
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid black;
        position: relative;
    }
    
    .sender {
        @include fs(15,18,semibold);
        
        margin-bottom: 4px;
    }
    
    .headline-container {
        
        margin-bottom: 16px;

    }
    
    .headline {
        @include fs(20,22,bold);
        
        margin-bottom: 8px;
    }
    
    .meta {
        @include fs(15,18);
        
        strong {
            @include fst(semibold);
        }
    }
    
    p.link {
        @include teaserLinkArrow;
        
        margin-bottom: 16px;
        //text-align: right;
        //@include fs(24,29);
        //
        //a {
        //    &:before {
        //        content: '>';
        //        display: inline-block;
        //        text-decoration: none;
        //        font-size: 20px;
        //        font-weight: 700;
        //        color: black;
        //    }
        //
        //    &:after {
        //        position: absolute;
        //        inset: 0;
        //        content: '';
        //    }
        //}
    }
    
    @include media-breakpoint-down(sm) {
        .headline {
            hyphens: auto;
        }
    }
    
    @include media-breakpoint-up(sm) {
        .date {
            @include fsc(56,56)
        }
        
        .content-container {
            min-height: 173px;
        }
    }
    
    @include media-breakpoint-up(md) {
        .date {
            @include fsc(80,80)
        }
        
        .headline-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            
            .label {
                margin-left: 16px;
            }
        }
        
        .headline {
            @include fsc(24,29);
        }
    }
    
    @include media-breakpoint-up(lg) {
        .date {
            @include fsc(56,56)
        }
        
        .sender {
            @include fsc(16,16);
            
            margin-bottom: 8px;
        }
    }
    
    @include media-breakpoint-up(xl) {
        .date {
            @include fsc(64,64)
        }
    }
}

.content-eventtip-teaser-element {
    .link-container {
        text-align: right;
    }
}

.eventtip-list {
    .eventtip-list-container {
        @include marginBottomStd;
    }
}

.eventtip-filters {
    @include marginBottomStd;
    
    button {
        @include button('yellow');
        
        white-space: nowrap;
        margin-top: 12px;
    }
}