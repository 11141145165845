.mod_breadcrumb {
    margin-bottom: 16px;
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        display: flex;
        flex-wrap: wrap;
    }
    
    li {
        @include fs(15,15,reg);
        
        margin-right: 5px;
        
        > a,
        > strong {
            color: inherit;
            
        }
        
        > a {
            text-decoration: none;

            &:after {
                content: '>';
                display: inline-block;
                //width: 20px;
                padding-left: 5px;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
    
    @include media-breakpoint-down(md) {
        display: none;
    }
}