@mixin articlePaddingStandard() {
    padding-top: 48px;
    padding-bottom: 48px;
    
    @include media-breakpoint-up(lg) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    
    @include media-breakpoint-up(xl) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@mixin articleLargePaddingTop() {
    padding-top: 96px;
    
    @include media-breakpoint-up(lg) {
        padding-top: 128px;
    }
    
    @include media-breakpoint-up(xl) {
        padding-top: 160px;
    }
}

@mixin articleLargePaddingBottom() {
    padding-bottom: 96px;
    
    @include media-breakpoint-up(lg) {
        padding-bottom: 128px;
    }
    
    @include media-breakpoint-up(xl) {
        padding-bottom: 160px;
    }
}

@mixin articleSmallPadding() {
    padding-top: 24px;
    padding-bottom: 24px;
    
    @include media-breakpoint-up(lg) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    
    @include media-breakpoint-up(xl) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

@mixin articleNoPadding() {
    padding-top: 0;
    padding-bottom: 0;
    
    @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: 0;
    }
    
    @include media-breakpoint-up(xl) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@mixin articleNoPaddingTop() {
    padding-top: 0;
    
    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
    
    @include media-breakpoint-up(xl) {
        padding-top: 0;
    }
}

@mixin articleNoPaddingBottom() {
    padding-bottom: 0;
    
    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
    
    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
    }
}

@mixin articlePaddingBackground() {
    padding-top: 64px;
    padding-bottom: 64px;
    
    @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}


.mod_article.standard {
    //@include make-container();
}

.mod_article {
    //@include articlePaddingStandard;
    
    border-color: black;
    
    &.grey,
    &.dark,
    &.petrol,
    &.lightpetrol,
    &.darkpetrol,
    &.with-bg-image {
        //@include articlePaddingBackground;
        
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    &.dark {
        background-color: black;
    }
    
    &.grey {
        background-color: $neutralWarmGrey;
    }
    
    &.lightpetrol {
        background-color: map-get($box-bg-colors, 'lightPetrol');
    }
    
    &.petrol {
        background-color: map-get($box-bg-colors, 'petrol');
        color: white;
        border-color: white;
    }
    
    &.darkpetrol {
        background-color: map-get($box-bg-colors, 'darkpetrol');
        color: white;
        border-color: white;
    }
}

.mod_article.article-fullwidth {
    &.colset-teaser {
        > .inside > .ce_row {
            margin-left: 0;
            margin-right: 0;
            
            //@include media-breakpoint-up(lg) {
            padding: 0 24px;
            //}
        }
    }
}

.mod_article.article-fullwidth {
    &.container-md-down {
        > .inside > .ce_row {
            margin-left: 0;
            margin-right: 0;
            
            @include media-breakpoint-up(lg) {
                padding: 0 12px;
            }
        }
    }
}

.container-md-down {
    > .inside {
        @include makeContainerMd;
    }
}

.main .mod_article {
    //&:not(.grey):not(.petrol):not(.lightpetrol):not(.darkpetrol) {
    @include articlePaddingStandard;
    
    &.no-padding {
        @include articleNoPadding;
    }
    
    &.no-padding-top {
        @include articleNoPaddingTop;
    }
    
    &.no-padding-bottom {
        @include articleNoPaddingBottom;
    }
    
    &.large-padding-top {
        @include articleLargePaddingTop;
    }
    
    &.large-padding-bottom {
        @include articleLargePaddingBottom;
    }
    
    &.small-padding {
        @include articleSmallPadding;
    }
    
    //}
    
    > .inside {
        > div:last-child {
            margin-bottom: 0 !important;
        }
    }
    
    //&.padding-top {
    //    padding-top: 64px;
    //}
    //
    //&.padding-bottom {
    //    padding-bottom: 64px;
    //}
    
    &.with-bg-image {
        position: relative;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        
        .article-bg-image {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 50px;
            z-index: 100;
            
            .c-image-container {
                height: 100%;
                
                &__copyright-link {
                    //bottom: -70px;
                }
            }
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        > .inside {
            position: relative;
            z-index: 2;
        }
    }
    
}