.content-project-teaser-element {
    @include imageStandardNoCaption;
    
    // slider see slider/_maskslider
    
    .link-container {
        margin-top: 32px;
        
        text-align: right;
    }
    
    //.mask-container {
    //    overflow-x: hidden;
    //    mask-image: linear-gradient(to right, transparent,
    //        black 5%,
    //        black 95%,
    //        transparent 100%);
    //}
    //
    //.navigation {
    //    position: relative;
    //}
    //
    ////-webkit-mask-image: linear-gradient( to right,transparent,
    ////    black 20%,
    ////    black 80%,
    ////    transparent 100%);
    //
    //.container {
    //    position: relative;
    //}
    //
    //.swiper-button-prev,
    //.swiper-button-next {
    //    top: -72px;
    //    right: 0;
    //    bottom: auto;
    //    left: auto;
    //    width: 40px;
    //    height: 40px;
    //    border-radius: 50%;
    //    background-color: $neutralWarmGrey;
    //    margin-top: 0;
    //
    //    &.swiper-button-disabled {
    //        opacity: 0.8;
    //    }
    //}
    //
    //.swiper-button-prev {
    //    right: 58px
    //}
    //
    //.swiper-button-next {
    //    right: 12px;
    //}
    //
    //.swiper-slide {
    //    height: auto;
    //    align-self: stretch;
    //    //width: 440px;
    //}
    
}

.project-list {
    @include marginLG;
    
    @include media-breakpoint-up(md) {
        .project-list-container {
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            margin-right: -12px;
            
            > div {
                width: 50%;
                padding: 12px;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        .project-list-container {
            > div {
                width: 33.333%;
                
                .inside {
                    height: 100%;
                }
            }
        }
    }
}

.project-filters {
    @include marginBottomStd;
    
    text-align: right;
    
    .project-filters-container {
        @include media-breakpoint-up(md) {
            //display: flex;
            //flex-wrap: wrap;
            //align-items: flex-end;
            //
            //> .widget {
            //    width: 50%;
            //    margin-right: 24px;
            //}
        }
        
        @include media-breakpoint-up(lg) {
            //display: flex;
            //flex-wrap: nowrap;
            //
            //> .widget {
            //    width: 20%;
            //    margin-bottom: 0;
            //}
        }
    }
    
    
    
    button {
        @include button('yellow');
        
        white-space: nowrap;
        margin-top: 12px;
    }
}

.content-project-teaser-element,
.project-list {
    .project-teaser {
        @include imageStandardNoCaption;
        
        height: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .content-container {
            height: auto;
            position: relative;
            flex-grow: 2;
            
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            padding: 24px 0 18px 0;
        }
        
        .text {
            margin-bottom: 16px;
            
            p {
                @include fs(20, 22, bold);
            }
        }
        
        p.meta {
            @include fs(16, 16);
            
            margin-bottom: 6px;
        }
        
        p.link {
            text-align: right;
            margin-bottom: 0;
            
            @include fs(24, 29);
            
            a {
                &:before {
                    @include addIcon('linkStandardBold', 20px);
                    
                    text-decoration: none;
                    color: black;
                }
                
                &:after {
                    position: absolute;
                    inset: 0;
                    content: '';
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            .text p {
                @include fsc(24, 29);
            }
        }
    }
}