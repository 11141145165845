.content-swiper.standard {
    position: relative;
    
    .swiper {
        padding-top: 60px;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        border: 0;
    }
    
    .swiper-button-prev {
        @include buttonPrev;
        
        right: 46px
    }
    
    .swiper-button-next {
        @include buttonNext;
        
        right: 0;
    }
    
    .swiper-pagination {
        display: none;
    }
    
    .js-swiper-triple,
    .js-swiper-triple-mask {
        margin-left: -1 * calc($grid-gutter-width / 2);
        margin-right: -1 * calc($grid-gutter-width / 2);
        
        .swiper-button-prev {
            right: calc(46px + ($grid-gutter-width / 2));
        }
        
        .swiper-button-next {
            right: calc(($grid-gutter-width / 2));
        }
        
        .swiper-slide {
            //width: calc(33.333% - $grid-gutter-width);
            padding-left: calc($grid-gutter-width / 2);
            padding-right: calc($grid-gutter-width / 2);
            
            //margin-right: calc($grid-gutter-width);
        }
    }
}

.content-swiper.mask-slider {
    @include maskSlider;
    
    .swiper {
        overflow: visible;
        padding-top: 0;
    }
}
