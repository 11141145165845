$bgFadeDuration: 5s;

$mapFadeDuration: 3s;
$mapFadeDelay: 1s;

$headlineSlideDuration: 1s;
//$headlineSlideDelay: 1s;
$headlineSlideDelay: 0.1s;
//$headlineFadeDuration: 7s;
$headlineFadeDuration: 2s;
$headlineFadeDelay: 0s;
$headlineScaleDuration: 2s;
//$headlineScaleDelay: 4s;
$headlineScaleDelay: 1.5s;

$textFadeDuration: 2s;
//$textFadeDelay1: 7s;
$textFadeDelay1: 3.5s;
$textFadeDelay2: $textFadeDelay1 + 1s;

//$logoSlideInDuration: 2s;
$logoSlideInDuration: 1.5s;
//$logoSlideInDelay: 2s;
$logoSlideInDelay: 0.1s;
//$logoSlideLeftDuration: 1.5s;
$logoSlideLeftDuration: 1.3s;
//$logoSlideLeftDelay: 4.5s;
//$logoSlideLeftDelay: 1.5s;
$logoSlideLeftDelay: $logoSlideInDuration + $logoSlideInDelay;
//$logoScaleDuration: 1.5s;
$logoScaleDuration: $logoSlideLeftDuration;
//$logoScaleDelay: 3s;
$logoScaleDelay: $logoSlideLeftDelay;

//$cardStartDelay: 5s;
$cardStartDelay: 2s;
//$cardStartDelay: 0.5s;
$cardSlideDuration: 1s;
$cardSlideDelayInterval: 0.75s;
$cardSwingDuration: 2s;
$cardFirstSwingDuration: 2.5s;


@mixin aasAnimateCard($delay) {
    animation: aasSlideCard $cardSlideDuration linear $delay forwards;
    
    .inside {
        animation: aasFirstSwingCard $cardFirstSwingDuration ease-out $delay+$cardSlideDuration forwards,
        aasSwingCard $cardSwingDuration ease-in-out $delay+$cardSlideDuration+$cardFirstSwingDuration infinite alternate;
    }
}

.asa-anniversary .header-navigation {
    animation: aasFadeInBackground $bgFadeDuration ease-out forwards;
    
    .inside {
        padding-top: 25px;
    }
}

.asa-anniversary-stage {
    width: 100%;
    height: 850px;
    padding-top: 50px;
    padding-bottom: 80px;
    animation: aasFadeInBackground $bgFadeDuration ease-out forwards;
    overflow: hidden;
    
    > .container > .inside {
        height: 720px;
        position: relative;
    }
    
    .map {
        opacity: 0;
        animation: aasFadeInMap $mapFadeDuration ease-out $mapFadeDelay forwards;
        position: relative;
        
        img {
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);
        }
    }
    
    .headline {
        @include fs(128, 112, xbold);
        
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        color: black;
        transform: translateX(calc(-50vw - 100%));
        opacity: 0;
        scale: 1;
        transform-origin: left center;
        white-space: nowrap;
        
        span {
            color: $textPetrol;
            
        }
        
        animation: aasSlideInHeadline $headlineSlideDuration cubic-bezier(.14, .41, .46, 1) $headlineSlideDelay forwards,
        assFadeInHeadline $headlineFadeDuration ease-out forwards,
        assScaleHeadline $headlineScaleDuration cubic-bezier(.44, .01, .46, 1) $headlineScaleDelay forwards;
    }
    
    .text {
        width: 40%;
        position: absolute;
        top: 240px;
        left: 0;
        z-index: 1;
        color: black;
        
        .text1 {
            @include fs(24, 30, med);
            
            opacity: 0;
            animation: aasFadeInText $textFadeDuration ease-out $textFadeDelay1 forwards;
        }
        
        .text2 {
            @include fs(28, 34, bold);
            
            opacity: 0;
            animation: aasFadeInText $textFadeDuration ease-out $textFadeDelay2 forwards;
        }
    }
    
    .logo {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        transform: translateX(50vw);
        //opacity: 0;
        scale: 1;
        transform-origin: center center;
        
        //animation: aasSlideInLogo $logoSlideInDuration cubic-bezier(.14, .41, .46, 1) $logoSlideInDelay forwards,
        animation: aasSlideInLogo $logoSlideInDuration cubic-bezier(.2, 1.02, .49, .85) $logoSlideInDelay forwards,
            //aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.14, .41, .46, 1) $logoSlideLeftDelay forwards;
        aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.76, .3, .27, .81) $logoSlideLeftDelay forwards;
        //aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.63,.11,.69,.48) $logoSlideLeftDelay forwards;
        //aasScaleLogo $logoScaleDuration cubic-bezier(.44, .01, .46, 1) $logoScaleDelay forwards;
        
        img {
            scale: 1;
            transform-origin: left bottom;
            //animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.14, .41, .46, 1) $logoSlideLeftDelay forwards;
            animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.76, .3, .27, .81) $logoSlideLeftDelay forwards;
            //animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.63,.11,.69,.48) $logoSlideLeftDelay forwards;
        }
    }
    
    .card {
        position: absolute;
        top: 250px;
        z-index: 1;
        width: 260px;
        
        transform: translateX(calc(50vw + 150px));
        
        //animation: aasSlideCard 2s ease-in forwards;
        
        .inside {
            min-height: 472px;
            padding: 22px;
            border-radius: 16px;
            
            transform: rotate(-4deg);
            transform-origin: top center;
            
            // 'nail'
            //&:before {
            //    content: '';
            //    position: absolute;
            //    top: 5px;
            //    left: 50%;
            //    width: 10px;
            //    height: 10px;
            //    background-color: white;
            //    border-radius: 50%;
            //    transform: translateX(-50%);
            //}
        }
        
        figure {
            position: relative;
            
            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                background-image: url('#{$image-path}/quotation-marks-white.svg');
                background-repeat: no-repeat;
                background-position: top left;
                background-size: contain;
                width: 59px;
                height: 46px;
                content: '';
                display: block;
                margin-bottom: 8px;
                opacity: 0.82;
            }
        }
        
        .image {
            width: 100%; /* Adapts to the parent container */
            max-width: 300px; /* Optional: Prevents it from getting too large */
            aspect-ratio: 1 / 1; /* Ensures the height matches the width */
            object-fit: cover; /* Prevents distortion */
            border-radius: 50%; /* Creates a circular shape */
            margin-bottom: 15px;
            position: relative;
            
            
        }
        
        h3 {
            @include fs(16, 19);
            
            color: inherit;
        }
        
        p {
            @include fs(22, 24, xbold);
            
            margin-bottom: 15px;
        }
        
    }
    
    .card-petrol {
        .inside {
            background-color: map-get($box-bg-colors, 'petrol');
            color: map-get($box-fg-colors, 'petrol');
            
            a {
                @include button('darkPetrol');
            }
        }
    }
    
    .card-orange {
        .inside {
            background-color: map-get($box-bg-colors, 'orange');
            color: map-get($box-fg-colors, 'orange');
            
            a {
                @include button('petrol');
            }
        }
    }
    
    .card-yellow {
        .inside {
            background-color: map-get($box-bg-colors, 'yellow');
            color: map-get($box-fg-colors, 'yellow');
            
            a {
                @include button('petrol');
            }
        }
    }
    
    .card-1 {
        @include aasAnimateCard($cardStartDelay);
        
        right: 575px;
    }
    
    .card-2 {
        @include aasAnimateCard($cardStartDelay+$cardSlideDelayInterval);
        
        right: 325px;
        z-index: 2;
    }
    
    .card-3 {
        @include aasAnimateCard($cardStartDelay+2*$cardSlideDelayInterval);
        
        right: 75px;
    }
    
    @include media-breakpoint-down(sm) {
        $aasPaddingTopXs: 58px;
        $aasCardWithSm: 180px;
        
        height: 566px;
        padding-top: $aasPaddingTopXs;
        padding-bottom: 59px;
        
        > .container > .inside {
            height: calc(555px - $aasPaddingTopXs - 59px);
        }
        
        .headline {
            @include fs(calc(38 * 1.25), calc(36 * 1.25), xbold);
        }
        
        .text {
            width: 100%;
            top: calc(149px - $aasPaddingTopXs);
            
            .text1 {
                @include fs(18, 24, med);
            }
            
            .text2 {
                @include fs(20, 22, bold);
            }
        }
        
        .logo {
            top: calc(344px - $aasPaddingTopXs - 0.2 * 163px);
            bottom: auto;
            right: 0;
            
            img {
                width: calc(270px / 0.8);
                //width: 100%;
                height: auto;
            }
        }
        
        .card {
            display: none;
        }
    }
    
    
    @include media-breakpoint-up(sm) {
        $aasPaddingTopSm: 58px;
        $aasCardWithSm: 180px;
        
        height: 879px;
        padding-top: 59px;
        padding-bottom: 80px;
        
        > .container > .inside {
            height: 740px;
        }
        
        .headline {
            @include fs(calc(56 * 1.25), calc(50 * 1.25), xbold);
        }
        
        .text {
            width: 100%;
            top: calc(188px - $aasPaddingTopSm);
            
            .text1 {
                @include fs(18, 24, med);
            }
            
            .text2 {
                @include fs(20, 22, bold);
            }
        }
        
        .logo {
            top: calc(316px - $aasPaddingTopSm - 0.2 * 107px);
            bottom: auto;
            right: 0;
            
            img {
                width: calc(216px / 0.8);
                height: auto;
            }
        }
        
        .card {
            top: calc(460px - $aasPaddingTopSm);
            width: $aasCardWithSm;
            transform: translateX(100vw);
            
            .inside {
                min-height: 350px;
                padding: 15px;
                
                a {
                    @include fsc(12,13);
                    
                    padding: 7px 16px;
                    border-radius: 20px;
                }
            }
            
            figure {
                &:before {
                    width: 45px;
                    height: 38px;
                }
            }
            
            .image {
                max-width: 300px; /* Optional: Prevents it from getting too large */
            }
            
            h3 {
                @include fs(12, 15);
                
                color: inherit;
            }
            
            p {
                @include fs(16, 18, xbold);
                
                margin-bottom: 15px;
            }
            
        }
        
        
        .card-1 {
            right: calc(100% - $aasCardWithSm);
        }
        
        .card-2 {
            right: calc(47.5% - $aasCardWithSm/2);
        }
        
        .card-3 {
            right: -5%;
        }
        
    }
    
    @include media-breakpoint-up(md) {
        $aasPaddingTopMd: 58px;
        $aasCardWithMd: 180px;
        
        height: 720px;
        padding-top: 59px;
        padding-bottom: 80px;
        
        > .container > .inside {
            height: 580px;
        }
        
        .headline {
            @include fs(calc(68 * 1.25), calc(58 * 1.25), xbold);
        }
        
        .text {
            width: 100%;
            top: calc(210px - $aasPaddingTopMd);
            
            .text1 {
                @include fs(20, 26, med);
            }
            
            .text2 {
                @include fs(22, 28, bold);
            }
        }
        
        .logo {
            top: calc(327px - $aasPaddingTopMd - 0.2 * 107px);
            bottom: auto;
            right: 0;
            
            img {
                width: calc(216px / 0.8);
                height: auto;
            }
        }
        
        .card {
            top: calc(334px - $aasPaddingTopMd);
        }
        
        .card-1 {
            right: 40%;
        }
        
        .card-2 {
            right: 15%;
        }
        
        .card-3 {
            right: -10%;
        }
        
    }
    
    @include media-breakpoint-up(lg) {
        $aasPaddingTopLg: 58px;
        $aasCardWithLg: 190px;
        
        height: 611px;
        padding-top: $aasPaddingTopLg;
        padding-bottom: 60px;
        
        > .container > .inside {
            height: calc(611px - $aasPaddingTopLg - 60px);
        }

        
        .headline {
            @include fs(calc(68 * 1.25), calc(58 * 1.25), xbold);
        }
        
        .text {
            width: 50%;
            top: calc(215px - $aasPaddingTopLg);
            
            .text1 {
                @include fs(18, 24, med);
            }
            
            .text2 {
                @include fs(22, 28, bold);
            }
        }
        
        .logo {
            top: auto;
            bottom: 0;
            right: 0;
            
            img {
                width: calc(380px / 0.8);
                height: auto;
            }
        }
        
        .card {
            top: calc(220px - $aasPaddingTopLg);
            width: $aasCardWithLg;
            
            .inside {
                min-height: 365px;
            }
            
            figure {
                &:before {
                    width: 49px;
                    height: 38px;
                }
            }
            
            .image {
                max-width: 300px; /* Optional: Prevents it from getting too large */
            }
            
            h3 {
                @include fs(14, 17);
                
                color: inherit;
            }
            
            p {
                @include fs(18, 20, xbold);
                
                margin-bottom: 15px;
            }
        }
        
        
        .card-1 {
            right: 30%;
        }
        
        .card-2 {
            right: 10%;
        }
        
        .card-3 {
            right: -10%;
        }
        
    }
    
    
    @include media-breakpoint-up(xl) {
        $aasPaddingTopXl: 66px;
        $aasCardWithXl: 215px;
        
        height: 700px;
        padding-top: $aasPaddingTopXl;
        padding-bottom: 72px;
        
        > .container > .inside {
            height: calc(700px - $aasPaddingTopXl - 72px);
        }
        
        
        .headline {
            @include fs(calc(80 * 1.25), calc(68 * 1.25), xbold);
        }
        
        .text {
            width: 50%;
            top: calc(245px - $aasPaddingTopXl);
            
            .text1 {
                @include fs(22, 28, med);
            }
            
            .text2 {
                @include fs(26, 32, bold);
            }
        }
        
        .logo {
            top: auto;
            bottom: 0;
            right: 0;
            
            img {
                width: calc(380px / 0.8);
                height: auto;
            }
        }
        
        .card {
            top: calc(250px - $aasPaddingTopXl);
            width: $aasCardWithXl;
            
            .inside {
                min-height: 400px;
                
                a {
                    @include fsc(14,15);
                }
            }
        }
        
        
        .card-1 {
            right: 30%;
        }
        
        .card-2 {
            right: 11%;
        }
        
        .card-3 {
            right: -8%;
        }
        
    }
    
    
    @include media-breakpoint-up(xxl) {
        height: 850px;
        padding-top: 50px;
        padding-bottom: 80px;
        
        > .container > .inside {
            height: 720px;
        }
        
        .headline {
            @include fs(128, 112, xbold);
        }
        
        .text {
            width: 40%;
            top: 240px;
            
            .text1 {
                @include fs(22, 28, med);
            }
            
            .text2 {
                @include fs(28, 34, bold);
            }
        }
        
        .logo {
            bottom: 0;
            right: 0;
        }
        
        .card {
            top: 250px;
            width: 260px;
            
            .inside {
                min-height: 472px;
                padding: 22px;
                border-radius: 16px;
                
                a {
                    @include fsc(18,19);
                }
            }
            
            figure {
                &:before {
                    width: 59px;
                    height: 46px;
                }
            }
            
            .image {
                max-width: 300px; /* Optional: Prevents it from getting too large */
            }
            
            h3 {
                @include fs(16, 19);
                
                color: inherit;
            }
            
            p {
                @include fs(22, 24, xbold);
                
                margin-bottom: 15px;
            }
            
        }
        
        
        .card-1 {
            right: 575px;
        }
        
        .card-2 {
            right: 325px;
        }
        
        .card-3 {
            right: 75px;
        }
        
    }
}


// animations

@keyframes aasFadeInBackground {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(123, 218, 225, 1);
    }
}

@keyframes aasFadeInMap {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes aasSlideInHeadline {
    from {
        transform: translateX(-150%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes assFadeInHeadline {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes assScaleHeadline {
    from {
        scale: 1;
    }
    to {
        scale: 0.8;
    }
}

@keyframes aasFadeInText {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes aasSlideInLogo {
    0% {
        transform: translateX(+150%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes aasSlideLeftLogo {
    from {
        right: 0;
    }
    to {
        right: 100%;
        transform: translateX(100%);
    }
}

@keyframes aasScaleLogoImage {
    from {
        scale: 1;
    }
    to {
        scale: 0.8;
    }
}

@keyframes aasScaleLogo {
    0% {
        scale: 1;
    }
    50% {
        scale: 1.05;
    }
    100% {
        scale: 1;
    }
}

@keyframes aasFirstSwingCard {
    0% {
        transform: rotate(-4deg); /* Starting position */
    }
    30% {
        transform: rotate(7deg); /* Swing to the right */
    }
    100% {
        transform: rotate(-1deg); /* Swing to the left */
    }
}

@keyframes aasSwingCard {
    0% {
        transform: rotate(-1deg); /* Starting position */
    }
    100% {
        transform: rotate(1deg); /* Swing to the left */
    }
}

@keyframes aasSlideCard {
    0% {
        transform: translateX(calc(50vw + 50px));
    }
    100% {
        transform: translateX(0);
    }
}