.content-project {
    @include columnBox;
    
    .headline {
        @include fs(56,50,xbold);
        @include marginLG;
        
        //margin-bottom: 40px;
        color: inherit;
        
        span {
            color: $textTurquoise;
        }
    }
    
    table {
        @include marginBottomStd;
    }
    
    tr {
        &:not(:last-child) {
            td,
            th {
                padding-bottom: 8px;
            }
        }
    }
    
    td,
    th {
        @include fs(15,18);
        
        vertical-align: top;
    }
    
    th {
        @include fst(semibold);
        
        padding-right: 20px;
    }

    p {
        &:not(:last-child) {
            @include marginSM;
        }
    }
    
    p:not(.link) {
        @include fs(18,27);
        
        hyphens: auto;
    }
    
    p.link {
        a {
            @include button('cyan');
        }
    }
    
    @include media-breakpoint-up(lg) {
        .headline {
            @include fsc(72,65);
        }
        
        p:not(.link) {
            @include fsc(20,32);
        }
    }

}