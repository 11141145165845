.content-image:not(.image-left):not(.image-conver),
.content-gallery {
    figcaption {
        @include fs(15,18,reg);
        
        margin-top: 8px;
        padding-left: 16px;
        border-left: 1px solid $textBlack;
        margin-left: 1px;
    }
    
    @include media-breakpoint-up(lg) {
        figcaption {
            padding-left: 24px;
        }
    }
}

.content-image {
    img {
        width: 100%;
        height: auto;
    }

    .row {
        align-items: flex-end;
    }
    


    //&.standard {
    //    @include media-breakpoint-up(lg) {
    //        figure {
    //            display: flex;
    //            justify-content: flex-start;
    //            align-items: flex-end;
    //
    //            img {
    //                width: 50%;
    //            }
    //
    //            figcaption {
    //                width: 25%;
    //            }
    //        }
    //    }
    //}
}

.content-image.image-left {
    @include marginLeftCol;
    
    figure {
        margin: 0;
    }
    
    figcaption {
        @include fs(15,18);
        @include marginTopXS;
        
        padding-left: 24px;
    }
    
    //@include media-breakpoint-down(md) {
    //    margin-bottom: 40px;
    //}
}

.ce_row.image-set {
    @include media-breakpoint-down(md) {
        padding-left: 24px;
        padding-right: 24px;
        
        &.no-gutters {
            .ce_column:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }
    
    .content-text {
        p {
            hyphens: auto;
        }
    }
}

.content-image.image-plain,
.content-image.image-cover {
    @include imageStandardNoCaption;
}

.content-image.image-cover {
    height: 100%;
    
    figure,
    .c-image-container {
        height: 100%;
    }
    
    img {
        height: 100%;
        max-width: 10000px;
        object-fit: cover;
    }
}