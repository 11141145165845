@mixin fsc($fontSize, $lineHeight) {
    font-size: calc($fontSize / 16) * 1rem;
    line-height: calc($lineHeight / $fontSize);
    
    // font-size: $fontSize * 1px;
    // line-height: $lineHeight * 1px;
}

@mixin fst($font: reg) {
    font-style: normal;
    
    @if $font == ita {
        font-family: $font-ita;
        //font-style: italic;
        font-weight: 400;
    //} @else if $font == xbold {
    //    font-family: $font-web-xbold;
    //    //font-style: normal;
    //    //font-weight: 800;
    } @else if $font == xbold {
        font-family: $font-display;
        font-style: normal;
        //font-weight: 900;
    } @else if $font == semibold {
        font-family: $font-medium;
        font-style: normal;
        //font-weight: 600;
    } @else if $font == bold {
        font-family: $font-bold;
        font-style: normal;
        //font-weight: 700;
    //} @else if $font == semiboldita {
    //    font-family: $font-web-semibold-ita;
    //    //font-style: normal;
    //    //font-weight: 600;
    //} @else if $font == bold {
    //    font-family: $font-web-bold;
    //    //font-family: $font-web-reg;
    //    //font-style: normal;
    //    //font-weight: 700;
    //} @else if $font == ita {
    //    font-family: $font-web-reg;
    //    font-style: italic;
    //    font-weight: 400;
    } @else {
        font-family: $font-reg;
        font-style: normal;
        font-weight: 400;
    }
}

@mixin fs($fontSize, $lineHeight, $font: reg) {
    @include fst($font);
    @include fsc($fontSize, $lineHeight);
}
