.invisible {
    //visibility: hidden !important;
    
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

body {
    //&:before {
    //    content: 'xxs (0 <-> 320)';
    //    position: fixed;
    //    z-index: 10000;
    //    padding: 1px 3px;
    //    background-color: lightgreen;
    //    font-family: "Courier";
    //
    //    @include media-breakpoint-up(xs) {
    //        content: 'xs (320 <-> 576)';
    //    }
    //    @include media-breakpoint-up(sm) {
    //        content: 'sm (576 <-> 768)';
    //    }
    //    @include media-breakpoint-up(md) {
    //        content: 'md (768 <-> 992)';
    //    }
    //    @include media-breakpoint-up(lg) {
    //        content: 'lg (992 <-> 1200)';
    //    }
    //    @include media-breakpoint-up(xl) {
    //        content: 'xl (1200 <-> 1600)';
    //    }
    //    @include media-breakpoint-up(xxl) {
    //        content: 'xxl (1600 <-> oo)';
    //    }
    //}
}

.row.dev {
    margin-bottom: 40px;
    
    > div > div {
        background-color: lightgreen;
        height: 15px;
    }
}

.placeholder {
    @include marginLG;
    
    background-color: lightpink;
    padding: 60px 30px 30px 30px;
    position: relative;
    min-height: 300px;
    
    &:before {
        content: '[ Platzhalter ]';
        display: block;
        position: absolute;
        top: 30px;
        left: 30px;
        font-family: Courier;
    }
}

.doc {
    @include marginLG;
    
    background-color: lightsalmon;
    padding: 30px;
    font-size: 14px;

    font-family: Courier;
    
    h1,
    h2,
    h3 {
        font-weight: bold;
        font-size: 18px;
    }
    
    h2 {
        text-transform: uppercase;
        text-decoration: underline;
    }
    
    h3 {
        font-size: 16px;
    }
    
    table {
        margin-bottom: 15px;
    }
    
    th {
        padding-right: 20px;
    }
    
    ul {
        padding-left: 1.2rem;
    }
}

// ----------------------------------------------------------------

//figure {
//    img {
//        width: 200px;
//        height: auto;
//    }
//}



.mod_sitemap {
    .level_2 {
        padding-left: 20px;
    }
}

//header {
//    .mod_article {
//        @include make-container();
//
//        background-color: lightgrey;
//    }
//
//    .mod_breadcrumb {
//        @include make-container();
//
//
//    }
//}

.simple-table {
    th,
    td {
        padding: 1px 3px;
        border-right: 1px solid grey;
        vertical-align: top;
        
        &.id {
            text-align: right;
        }
        
        &.no-border {
            border-right: 0;
        }
    }
    
    th {
        border-bottom: 2px solid grey;
        font-weight: bold;
    }
    
    tr {
        &.start {
            td {
                border-top: 1px solid grey;
            }
        }
        
        &.secondary {
            &:not(:last-child) {
                td {
                    border-top: 1px solid lightgrey;
                }
            }
        }
    }
}