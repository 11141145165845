.content-teaser.standard {
    @include imageStandardNoCaption;
    
    hyphens: auto;
    height: 100%;
    
    //.inside {
    display: flex;
    flex-direction: column;
    //height: 100%;
    //background-color: white;
    //}
    
    .content-container {
        height: 100%;
        position: relative;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        padding: 24px 0;
    }
    
    .ce_text {
        flex-grow: 2;
    }
    
    h2,
    h3,
    h4 {
        @include fs(20, 22, semibold);
        
        color: inherit;
        margin-bottom: 4px;
    }
    
    .content {
        p {
            @include fs(16, 22);
        }
    }
    
    .teaser-link {
        @include teaserLinkArrow;
        
        a {
            font-size: 0;
            line-height: 0;
            color: inherit;
            
            &:before {
                color: inherit;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fsc(24, 29);
        }
    }
    
}

.content-teaser.teaser-standard-hor {
    @include imageStandardNoCaption;
    
    hyphens: auto;
    height: 100%;
    
    //.inside {
    display: flex;
    flex-direction: column;
    //height: 100%;
    //background-color: white;
    //}
    
    .content-container {
        height: 100%;
        position: relative;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        padding: 24px 0;
    }
    
    .ce_text {
        flex-grow: 2;
    }
    
    h2,
    h3,
    h4 {
        @include fs(20, 22, semibold);
        //@include fs(24, 26, xbold);
        
        color: inherit;
        margin-bottom: 4px;
    }
    
    .content {
        p {
            //@include fs(16, 22);
            @include fs(18, 27);
        }
        
        p,
        li {
            a {
                @include linkStandard;
                
                color: inherit;
            }
        }
    }
    
    .teaser-link {
        @include teaserLinkArrow;
        
        a {
            font-size: 0;
            line-height: 0;
            color: inherit;
            
            &:before {
                color: inherit;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;

        &.media--right {
            flex-direction: row-reverse;
        }
        
        > div {
            width: calc(50% - $grid-gutter-width/2);
        }
        
        h2,
        h3,
        h4 {
            @include fsc(24, 29);
        }
    }
    
}

.content-teaser.teaser-targetgroup {
    @include imageStandardNoCaption;
    
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .image-container {
        position: relative;
        z-index: 1;
    }
    
    .c-image-container__copyright-link {
        bottom: 90px;
    }
    
    .content-container {
        @include backgroundBox(white);
        
        position: relative;
        z-index: 2;
        margin: -80px 24px 0 24px;
        border-radius: 16px;
        flex-grow: 2;
    }
    
    h2,
    h3,
    h4 {
        @include fs(24, 26, xbold);
        @include marginXS;
        
        hyphens: auto;
    }
    
    p:not(.teaser-link) {
        @include fs(18, 27);
        
        hyphens: auto;
        
        strong {
            @include fst(semibold);
        }
    }
    
    p.meta {
        @include fs(16, 16);
        
        margin-bottom: 6px;
    }
    
    p.teaser-link {
        margin-top: 24px;
        margin-bottom: 0;
        
        a {
            @include button('cyan');
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fsc(32, 35);
        }
        
        p:not(.teaser-link) {
            //@include fsc(20,32);
        }
        
        .image-container {
            max-height: 640px;
            overflow: hidden;
            
            figure,
            .c-image-container {
                max-height: 640px;
            }
        }
    }
}

.colset-teaser {
    min-height: 800px;
    padding-top: 80px;
    padding-bottom: 80px;
    
    //> .inside {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //}
}

.content-teaser.teaser-bgcolor {
    @include imageStandardNoCaption;
    
    background-color: map-get($box-bg-colors, 'petrol');
    color: white;
    
    height: 100%;
    padding: 24px;
    border-radius: 16px;
    position: relative;
    
    &.yellow {
        background-color: map-get($box-bg-colors, 'yellow');
        color: black;
    }
    
    &.cyan {
        background-color: map-get($box-bg-colors, 'cyan');
        color: black;
    }
    
    &.orange {
        background-color: map-get($box-bg-colors, 'orange');
        color: black;
    }
    
    .image-container {
        display: none;
    }
    
    .content-container {
        height: 100%;
    }
    
    //h2,
    //h3,
    //h4 {
    //    @include fs(24,26,xbold);
    //    @include marginXS;
    //}
    
    p:not(.teaser-link) {
        @include fs(24, 26, xbold);
        
        hyphens: auto;
        
        strong {
            @include fst(semibold);
        }
    }
    
    p.teaser-link {
        margin-top: 24px;
        margin-bottom: 0;
        text-align: right;
        
        a {
            @include button('darkPetrol');
            
            &:after {
                position: absolute;
                inset: 0;
                content: '';
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        //h2,
        //h3,
        //h4 {
        //    @include fsc(32,35);
        //}
        
        
        .content-container {
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            > div {
                //flex-grow: 2;
            }
        }
        
        p:not(.teaser-link) {
            @include fsc(32, 35);
        }
    }
}

.content-teaser.teaser-left {
    @include marginLeftCol;
    
    .image-container {
        margin-bottom: 16px;
        
        img {
            width: 100%;
            height: auto;
        }
    }
    
    h2,
    h3,
    h4 {
        @include fs(18, 22, semibold);
        
        text-transform: uppercase;
        margin-bottom: 4px;
    }
    
    .content {
        margin-bottom: 10px;
        
        p {
            @include fs(16, 22);
            
            margin-bottom: 0;
            
            &:not(:last-child) {
                margin-bottom: 4px;
            }
            
            strong {
                @include fst(semibold);
            }
        }
    }
    
    p.teaser-link {
        margin-bottom: 0;
        
        a {
            @include linkStandard;
        }
    }
}

.content-teaser.teaser-content,
.content-teaser.teaser-content-image {
    background-color: map-get($box-bg-colors, 'petrol');
    color: white;
    
    //padding: 24px;
    border-radius: 16px;
    position: relative;
    
    .row &,
    .swiper-slide--multi & {
        height: 100%;
    }
    
    .image-col {
        //background-color: white;
    }
    
    &.grey {
        background-color: map-get($box-bg-colors, 'grey');
        color: black;
    }
    
    &.cyan {
        background-color: map-get($box-bg-colors, 'cyan');
        color: black;
    }
    
    &.yellow {
        background-color: map-get($box-bg-colors, 'yellow');
        color: black;
    }
    
    &.orange {
        background-color: map-get($box-bg-colors, 'orange');
        color: black;
    }
    
    .meta {
        @include fs(16, 16);
        
        margin-bottom: 8px;
    }
    
    h2,
    h3,
    h4 {
        @include fs(24, 26, xbold);
        @include marginXS;
        
        hyphens: auto;
        color: inherit;
        
        strong {
            @include fst(semibold);
        }
    }
    
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .content {
        p {
            @include fs(18, 27);
        }
    }
    
    p.teaser-link {
        margin-top: 24px;
        margin-bottom: 0;
        text-align: right;
        
        a {
            @include button('darkPetrol');
            @include linkParent;
        }
    }
    
    @include media-breakpoint-up(lg) {
        :not(.ce_column):not(.swiper-slide--multi) > & {
            .row {
                display: flex;
                flex-wrap: wrap;
            }

            .image-col {
                flex: 0 0 auto;
                width: 33.33333333%;
            }

            .text-col {
                flex: 0 0 auto;
                width: 66.66666667%;
            }
        }
        
        .content-container {
            //min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        
        h2,
        h3,
        h4 {
            @include fsc(32, 35);
        }
    }
}

.content-teaser.teaser-content {
    @include backgroundBox(tranparent);
    
    &:not(.nolink) {
        .content-container {
            min-height: 200px;
        }
    }
}

.content-teaser.teaser-content-image {
    overflow-x: hidden;
    //background-color: $neutralWarmGrey;
    //border-radius: 10px 10px 0 0;
    
    .image-container {
        height: 100%;
    }
    
    figure {
        margin: 0;
        height: 100%;
        
        > div {
            height: 100%;
        }
    }
    
    .content-container {
        @include backgroundBox(none);
    }
    
    img {
        width: 100%;
        height: 320px;
        object-fit: cover;
        //border-radius: 10px 10px 0 0;
    }
    
    @include media-breakpoint-up(lg) {
        .content-container {
            //min-height: 348px;
            height: 100%;
        }
    }
    
    @include media-breakpoint-up(lg) {
        //border-radius: 10px 0 0 10px;
        
        //.row {
        //    min-height: 350px;
        //}
        
        img {
            height: 100%;
            //border-radius: 10px 0 0 10px;
        }
    }
    
    @include media-breakpoint-up(xxl) {
        .row {
            min-height: 320px;
        }
    }
}

.content-teaser.teaser-topic {
    @include imageStandardNoCaption;
    
    //height: 400px;  // org
    height: 300px;
    border-radius: 16px;
    position: relative;
    background-color: map-get($box-bg-colors, 'yellow');
    
    &.cyan {
        background-color: map-get($box-bg-colors, 'cyan');
    }
    
    &.grey {
        background-color: map-get($box-bg-colors, 'grey');
    }
    
    .image-container,
    figure,
    .c-image-container {
        height: inherit;
    }
    
    .c-image-container__copyright-link {
        display: none;
    }
    
    .image-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: inherit;
        
        img {
            //object-fit: cover;  // org
            object-fit: contain;
            //object-position: center top;  // org
            object-position: center center;
            height: 100%;
            border-radius: 16px;
        }
    }
    
    .content-container {
        //position: absolute;
        //bottom: 0;
        //left: 0;
        position: relative;
        height: inherit;
        z-index: 102;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    p.teaser-link {
        margin: 0;
        
        a {
            @include fs(20, 18, xbold);
            @include linkParent;
            
            //&:after {
            //    inset: 0 40px 0 0;
            //}
            
            text-transform: uppercase;
            display: inline-block;
            border-radius: 40px;
            padding: 3px 6px 3px 12px;
            background-color: map-get($box-bg-colors, 'orange');
            color: black;
            text-decoration: none;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        .group-topic & {
            height: 440px;
        }
    }

    // new
    @include media-breakpoint-down(md) {
        height: 400px;
    }
    
    @include media-breakpoint-up(xl) {
        //height: 440px;  // org
        height: 320px;
    }
    
    @include media-breakpoint-up(xxl) {
        //height: 440px;  // org
        height: 380px;
    }
}

.content-teaser.teaser-engagement {
    @include imageStandardNoCaption;
    
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: map-get($box-bg-colors, 'yellow');
    border-radius: 16px;
    
    &.cyan {
        background-color: map-get($box-bg-colors, 'cyan');
    }
    
    .content-container {
        @include backgroundBoxLarge(transparent);
    }
    
    h2,
    h3 {
        @include fs(48, 48, xbold);
        
        margin-bottom: 40px;
    }
    
    .content {
        margin-bottom: 24px;
        
        p {
            @include fs(20, 30);
        }
    }
    
    .image-container {
        img {
            border-radius: 0 0 16px 16px;
        }
    }
    
    .teaser-link {
        margin-bottom: 0;
    }
    
    
    @include media-breakpoint-only(md) {
        .content-container {
            padding-bottom: 0;
            position: relative;
            z-index: 2;
        }
        
        .image-container {
            margin-top: -48px;
            position: relative;
            z-index: 1;
        }
    }
    
    @include media-breakpoint-down(lg) {
        background-image: none !important;
    }
    
    @include media-breakpoint-up(lg) {
        min-height: 640px;
        
        .image-container {
            display: none;
        }
        
        h2,
        h3 {
            @include fs(80, 80, xbold);
            
            margin-bottom: 76px;
        }
        
        .content {
            p {
                @include fs(24, 36);
                
                width: 50%;
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        //height: 744px;
        height: 640px;
        
        .content-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 64px;
        }
        
        //.content {
        h2,
        h3 {
            @include fs(90, 90, xbold);
            
            flex-grow: 2;
            margin-bottom: 20px;
        }
        //}
        
        //.teaser-link {
        //    flex-grow: 2;
        //}
    }
    
    @include media-breakpoint-up(xxl) {
        //height: 800px;
        
        //.content {
        //    h2,
        //    h3 {
        //        @include fs(110,100,xbold);
        //    }
        //}
    }
}

.content-teaser.teaser-button {
    h2,
    h3,
    h4 {
        @include fs(28, 31, semibold);
        
        hyphens: auto;
        margin-bottom: 24px;
    }
    
    .content {
        p {
            @include paragraphStandard;
        }
    }
    
    p.teaser-link {
        margin-top: 24px;
        margin-bottom: 0;
        
        a {
            @include button('darkPetrol');
            @include buttonInt;
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fsc(32, 35);
        }
    }
}