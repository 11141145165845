.content-accordion {
    .handorgel__header {
        &:not(:first-child) {
            margin-top: 2px;
        }
    }
    
    .handorgel__header__button {
        @include fs(28,34,semibold);
        
        hyphens: auto;
        margin: 0;
        border: 0;
        padding: 16px 96px 16px 24px;
        background-color: $accordion-inactive-bg;
        position: relative;
        
        &:after {
            @include addIcon('accordion-closed', 20px);
            
            line-height: 40px;
            
            //content: "v";
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 16px;
            right: 24px;
            background-color: white;
            border-radius: 50%;
            text-align: center;
            //padding: 12px 0;
            //padding-top: 4px;
        }
        
        &:hover {
            &:after {
                //background-color: $accordion-inactive-bg;
                background-color: rgba(255,255,255,0.7);
            }
        }
    }
    
    .handorgel__header--open {
        .handorgel__header__button {
            background-color: $accordion-active-bg;
            
            &:after {
                @include addIcon('accordion-opened', 20px);
                padding-top: 12px;
            }
        }
    }
    
    .handorgel__content__inner {
        padding: 0 24px 24px 24px;
        background-color: $accordion-active-bg;
        
        //p {
        //    @include paragraphStandard;
        //}
    }
    
    @include media-breakpoint-only(md) {
        .handorgel__header__button {
            padding: 16px 88px 16px 32px;
            hyphens: none;
            
            &:after {
                top: 16px;
                right: 32px;
            }
        }
        
        .handorgel__content__inner {
            padding: 0 32px 24px 32px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        .handorgel__header__button {
            padding: 24px 96px 24px 40px;
            hyphens: none;
            
            &:after {
                top: 24px;
                right: 40px;
            }
        }
        
        .handorgel__content__inner {
            padding: 0 40px 24px 40px;
        }
    }
    
    .content-element-group {
        > div:not(:last-child) {
            @include marginLG;
        }
    }
}