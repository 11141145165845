.mod_search {
    @include marginBottomStd;
    
    .formbody {
        @include marginBottomStd;
        
        .widget-text {
            margin-bottom: 8px;
            
            input {
                @include fs(16, 19, reg);
                
                width: 100%;
                padding: 11px 16px;
                background-color: $form-widget-bg;
                border: 0;
            }
        }
        
        .widget-submit {
            button {
                @include button('yellow');
            }
        }
    }
    
    @include media-breakpoint-up(md) {
        .formbody {
            display: flex;
            
            .widget-text {
                flex-grow: 1;
                margin-right: 8px;
                margin-bottom: 0;
            }
        }
        
    }
}
.search_default {
    padding-bottom: 32px;
    border-bottom: 1px solid black;
    margin-bottom: 32px;
    
    h3 {
        @include marginXS;

        > a {
            @include headlineLevel2;
            
            text-decoration: none;
            color: black;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    p.context {
        @include paragraphStandard;
    }
    
    p.url {
        @include paragraphStandard;
        @include fst(semibold);
        
        margin-bottom: 0;
    }
}