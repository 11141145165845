.content-element-group.group-teaser50 {
    > div {
        @include marginBottomStd;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        > div {
            width: calc(50% - 12px);
            height: inherit;
        }
    }
}

.content-element-group.group-topic {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .content-teaser {
        width: 100%;
        //margin: 0 12px 24px 12px;
        margin-bottom: 24px;
    }
    
    @include media-breakpoint-between(md, lg) {
        .content-teaser {
            margin-right: 24px;
            
            &:nth-child(2n) {
                margin-right: 0;
            }
            
            width: calc((100% - 24px) / 2);
        }
    }
    
    @include media-breakpoint-only(lg) {
        .content-teaser {
            margin-right: 24px;
            
            &:nth-child(3n) {
                margin-right: 0;
            }
            
            width: calc((100% - 48px) / 3);
        }
    }
    
    @include media-breakpoint-only(xl) {
        .content-teaser {
            margin-right: 24px;
            
            //&:nth-child(3n) {
            //    margin-right: 0;
            //}
            //width: calc((100% - 48px) / 3);
            &:nth-child(4n) {
                margin-right: 0;
            }
            
            width: calc((100% - 72px) / 4);
        }
    }
    
    @include media-breakpoint-only(xxl) {
        .content-teaser {
            margin-right: 24px;
            
            //&:nth-child(4n) {
            //    margin-right: 0;
            //}
            //width: calc((100% - 72px) / 4);
            &:nth-child(5n) {
                margin-right: 0;
            }
            
            width: calc((100% - 96px) / 5);
        }
    }
}