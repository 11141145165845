body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row {
  max-width:10000px;
  //margin-left:0;
  //margin-right:0;
}

* { box-sizing: border-box; }
* { margin:0; padding:0; }

h1, h2, h3, h4, h5, h6, p {
  font-weight:normal;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

h1, h2, h3, h4, h5, h6,
figure,
ul, ol
{ margin:0; padding:0; }

ul, ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}

th {
  font-weight: normal;
}

strong {
  font-weight: normal;
}

abbr[title] {
  //border-bottom: none !important;
  border-bottom: 1px dotted;
  //cursor: inherit !important;
  text-decoration: none !important;
  //text-decoration: dotted underline;
}