$bgFadeDuration: 5s;

$mapFadeDuration: 3s;
$mapFadeDelay: 1s;

$headlineSlideDuration: 1s;
//$headlineSlideDelay: 1s;
$headlineSlideDelay: 0.1s;
//$headlineFadeDuration: 7s;
$headlineFadeDuration: 2s;
$headlineFadeDelay: 0s;
$headlineScaleDuration: 2s;
//$headlineScaleDelay: 4s;
$headlineScaleDelay: 1.5s;

$textFadeDuration: 2s;
//$textFadeDelay1: 7s;
$textFadeDelay1: 3.5s;
$textFadeDelay2: $textFadeDelay1 + 1s;

//$logoSlideInDuration: 2s;
$logoSlideInDuration: 1.5s;
//$logoSlideInDelay: 2s;
$logoSlideInDelay: 0.1s;
//$logoSlideLeftDuration: 1.5s;
$logoSlideLeftDuration: 1.3s;
//$logoSlideLeftDelay: 4.5s;
//$logoSlideLeftDelay: 1.5s;
$logoSlideLeftDelay: $logoSlideInDuration + $logoSlideInDelay;
//$logoScaleDuration: 1.5s;
$logoScaleDuration: $logoSlideLeftDuration;
//$logoScaleDelay: 3s;
$logoScaleDelay: $logoSlideLeftDelay;

//$cardStartDelay: 5s;
$cardStartDelay: 2s;
//$cardStartDelay: 0.5s;
$cardSlideDuration: 1s;
$cardSlideDelayInterval: 0.75s;
$cardSwingDuration: 2s;
$cardFirstSwingDuration: 2.5s;


@mixin aasAnimateCard($delay) {
    animation: aasSlideCard $cardSlideDuration linear $delay forwards;
    
    .inside {
        animation: aasFirstSwingCard $cardFirstSwingDuration ease-out $delay+$cardSlideDuration forwards,
        aasSwingCard $cardSwingDuration ease-in-out $delay+$cardSlideDuration+$cardFirstSwingDuration infinite alternate;
    }
}

.asa-anniversary .header-navigation {
    animation: aasFadeInBackground $bgFadeDuration ease-out forwards;

    .inside {
        padding-top: 25px;
    }
}

.asa-anniversary-stage {
    width: 100%;
    height: 850px;
    padding-top: 50px;
    padding-bottom: 80px;
    animation: aasFadeInBackground $bgFadeDuration ease-out forwards;
    overflow: hidden;
    
    > .inside {
        height: 720px;
        position: relative;
    }
    
    .map {
        opacity: 0;
        animation: aasFadeInMap $mapFadeDuration ease-out $mapFadeDelay forwards;
    }
    
    .headline {
        @include fs(128, 112, xbold);
        
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        color: black;
        transform: translateX(calc(-50vw - 100%));
        opacity: 0;
        scale: 1;
        transform-origin: left center;
        
        span {
            color: $textPetrol;
        }
        
        animation: aasSlideInHeadline $headlineSlideDuration cubic-bezier(.14, .41, .46, 1) $headlineSlideDelay forwards,
        assFadeInHeadline $headlineFadeDuration ease-out forwards,
        assScaleHeadline $headlineScaleDuration cubic-bezier(.44, .01, .46, 1) $headlineScaleDelay forwards;
    }
    
    .text {
        width: 40%;
        position: absolute;
        top: 240px;
        left: 0;
        z-index: 1;
        color: black;
        
        .text1 {
            @include fs(24, 30, med);
            
            opacity: 0;
            animation: aasFadeInText $textFadeDuration ease-out $textFadeDelay1 forwards;
        }
        
        .text2 {
            @include fs(28, 34, bold);
            
            opacity: 0;
            animation: aasFadeInText $textFadeDuration ease-out $textFadeDelay2 forwards;
        }
    }
    
    .logo {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        transform: translateX(50vw);
        //opacity: 0;
        scale: 1;
        transform-origin: center center;
        
        //animation: aasSlideInLogo $logoSlideInDuration cubic-bezier(.14, .41, .46, 1) $logoSlideInDelay forwards,
        animation: aasSlideInLogo $logoSlideInDuration cubic-bezier(.2,1.02,.49,.85) $logoSlideInDelay forwards,
        //aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.14, .41, .46, 1) $logoSlideLeftDelay forwards;
        aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.76,.3,.27,.81) $logoSlideLeftDelay forwards;
        //aasSlideLeftLogo $logoSlideLeftDuration cubic-bezier(.63,.11,.69,.48) $logoSlideLeftDelay forwards;
        //aasScaleLogo $logoScaleDuration cubic-bezier(.44, .01, .46, 1) $logoScaleDelay forwards;
        
        img {
            scale: 1;
            transform-origin: left bottom;
            //animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.14, .41, .46, 1) $logoSlideLeftDelay forwards;
            animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.76,.3,.27,.81) $logoSlideLeftDelay forwards;
            //animation: aasScaleLogoImage $logoSlideLeftDuration cubic-bezier(.63,.11,.69,.48) $logoSlideLeftDelay forwards;
        }
    }
    
    .card {
        position: absolute;
        top: 250px;
        z-index: 1;
        width: 260px;
        
        transform: translateX(calc(50vw + 150px));
        
        //animation: aasSlideCard 2s ease-in forwards;
        
        .inside {
            min-height: 472px;
            padding: 22px;
            border-radius: 16px;
            
            transform: rotate(-4deg);
            transform-origin: top center;
            
            // 'nail'
            //&:before {
            //    content: '';
            //    position: absolute;
            //    top: 5px;
            //    left: 50%;
            //    width: 10px;
            //    height: 10px;
            //    background-color: white;
            //    border-radius: 50%;
            //    transform: translateX(-50%);
            //}
        }
        
        figure {
            position: relative;
            
            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                background-image: url('#{$image-path}/quotation-marks-white.svg');
                background-repeat: no-repeat;
                background-position: top left;
                background-size: contain;
                width: 59px;
                height: 46px;
                content: '';
                display: block;
                margin-bottom: 8px;
                opacity: 0.82;
            }
        }
        
        .image {
            width: 100%; /* Adapts to the parent container */
            max-width: 300px; /* Optional: Prevents it from getting too large */
            aspect-ratio: 1 / 1; /* Ensures the height matches the width */
            object-fit: cover; /* Prevents distortion */
            border-radius: 50%; /* Creates a circular shape */
            margin-bottom: 15px;
            position: relative;
            

        }
        
        h3 {
            @include fs(16, 19);
            
            color: inherit;
        }
        
        p {
            @include fs(22, 24, xbold);
            
            margin-bottom: 15px;
        }
        
    }
    
    .card-petrol {
        .inside {
            background-color: map-get($box-bg-colors, 'petrol');
            color: map-get($box-fg-colors, 'petrol');
            
            a {
                @include button('darkPetrol');
            }
        }
    }
    
    .card-orange {
        .inside {
            background-color: map-get($box-bg-colors, 'orange');
            color: map-get($box-fg-colors, 'orange');
            
            a {
                @include button('petrol');
            }
        }
    }
    
    .card-yellow {
        .inside {
            background-color: map-get($box-bg-colors, 'yellow');
            color: map-get($box-fg-colors, 'yellow');
            
            a {
                @include button('petrol');
            }
        }
    }
    
    .card-1 {
        @include aasAnimateCard($cardStartDelay);
        
        right: 575px;
    }
    
    .card-2 {
        @include aasAnimateCard($cardStartDelay+$cardSlideDelayInterval);
        
        right: 325px;
        z-index: 2;
    }
    
    .card-3 {
        @include aasAnimateCard($cardStartDelay+2*$cardSlideDelayInterval);
        
        right: 75px;
    }
}


// animations

@keyframes aasFadeInBackground {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(123, 218, 225, 1);
    }
}

@keyframes aasFadeInMap {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes aasSlideInHeadline {
    from {
        transform: translateX(-150%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes assFadeInHeadline {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes assScaleHeadline {
    from {
        scale: 1;
    }
    to {
        scale: 0.8;
    }
}

@keyframes aasFadeInText {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes aasSlideInLogo {
    0% {
        transform: translateX(+150%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes aasSlideLeftLogo {
    from {
        right: 0;
    }
    to {
        right: 100%;
        transform: translateX(100%);
    }
}

@keyframes aasScaleLogoImage {
    from {
        scale: 1;
    }
    to {
        scale: 0.8;
    }
}

@keyframes aasScaleLogo {
    0% {
        scale: 1;
    }
    50% {
        scale: 1.05;
    }
    100% {
        scale: 1;
    }
}

@keyframes aasFirstSwingCard {
    0% {
        transform: rotate(-4deg); /* Starting position */
    }
    30% {
        transform: rotate(7deg); /* Swing to the right */
    }
    100% {
        transform: rotate(-2deg); /* Swing to the left */
    }
}

@keyframes aasSwingCard {
    0% {
        transform: rotate(-2deg); /* Starting position */
    }
    100% {
        transform: rotate(2deg); /* Swing to the left */
    }
}

@keyframes aasSlideCard {
    0% {
        transform: translateX(calc(50vw + 50px));
    }
    100% {
        transform: translateX(0);
    }
}