.product-filter {
    legend {
        @include fs(28, 31, semibold);
        
        margin-bottom: 24px;
    }
    
    .toggle-container {
        display: flex;
        margin-bottom: 24px;
    }
    
    .filter-toggle {
        @include fs(32, 35, semibold);
        @include resetButton;
        
        margin-left: auto;
        color: black;
        
        &:after {
            @include addIcon('filter-add', 30px);
            
            font-weight: bold;
            transform: translateY(4px);
            //content: '+';
            //display: inline-block;
            //margin-left: 16px;
        }
        
        &[aria-expanded="true"] {
            &:after {
                @include addIcon('filter-close', 30px);
                
                font-weight: normal;
            }
        }
    }
    
    
}

.product-intro {
    p {
        @include paragraphStandard;
        @include fst(semibold);
    }
}

@include media-breakpoint-up(md) {
    .product-filter {
        .toggle-container {
            display: none;
        }
        
        .collapse:not(.show) {
            display: block;
            height: auto !important;
            visibility: visible;
        }
        
        .collapsing {
            position: relative;
            height: unset !important;
            overflow: hidden;
        }
    }
}

.product-filter__container {
    @include marginLG;
}

.product-filter__list {
    @include marginSM;
    
    display: flex;
    flex-wrap: wrap;
    
}

.product-filter__item {
    margin-right: 10px;
    margin-bottom: 10px;
    
    label {
        &:after {
            @include addIcon('filter-add', 16px);
            
            font-weight: bold;
            //margin-left: 10px;
            //width: 1rem;
            //height: 1rem;
            //display: inline-block;
            //content: "";
            vertical-align: -.125em;
            //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'><path d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'/></svg>");
            //background-repeat: no-repeat;
            //background-size: 1rem 1rem;
        }
    }
    
    input:checked + label {
        background-color: black;
        color: white;
        
        &:after {
            @include addIcon('filter-close', 16px);
            
            font-weight: normal;
            //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z'/></svg>");
        }
    }
}

@include media-breakpoint-up(lg) {
    .product-filter {
        legend {
            @include fs(32, 35, semibold);
        }
    }
}

//<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
//<path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
//</svg>

//<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
//<path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
//</svg>

//<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
//<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
//</svg>

.product-field {
    &__container {
        padding: 0 24px;
    }
    
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -12px;
        
        @each $name, $color in $box-bg-colors {
            &--#{$name} {
                .product-field__item--first,
                .product-field__item--last {
                    .inside {
                        background-color: $color;
                        color: map-get($box-fg-colors, $name);
                    }
                }
            }
        }
        
        &--petrol,
        &--blue,
        &--red {
            color: white;
            
            .button-transparent-thin {
                border-color: white;
                color: white;
            }
        }
        
        
    }
    
    @include media-breakpoint-down(md) {
        &__list {
            .button-transparent-thin {
                &:after {
                    @include setIcon('dropdown-closed');
                }
            }
        }
    }
    
    &__item {
        width: 100%;
        padding: 12px;
        //margin-bottom: 20px;
        
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
        
        figcaption {
            display: none;
        }
        
        .inside {
            height: 100%;
            position: relative;
            background-color: white;
            color: black;
            
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        
        &--first,
        &--last {
            .inside {
                border: 0;
                border-radius: 16px;
                padding: 24px;
            }
        }
        
        &--first {
            .inside {
                
                //display: flex;
                //flex-direction: column;
                //justify-content: space-between;
            }
            
            h2 {
                @include fs(16, 16, semibold);
                
                margin-bottom: 6px;
                color: inherit;
            }
            
            .top {
                p {
                    @include fs(24, 26, xbold);
                    
                    hyphens: auto;
                }
            }
            
            .bottom {
                p {
                    @include fs(15, 18);
                    
                    text-align: right;
                    margin-bottom: 0;
                }
            }
        }
        
        &--last {
            cursor: pointer;
            
            h3 {
                @include fs(24, 26, xbold);
                
                hyphens: auto;
                color: inherit;
            }
            
            .button-container {
                text-align: right;
            }
        }
        
        &--product {
            .top {
                flex-grow: 4;
                padding: 24px 24px 0 24px;
                hyphens: auto;
            }
            
            .bottom {
                padding: 0 24px 18px 24px;
            }
            
            h3 {
                @include fs(20, 22, bold);
                
                margin-bottom: 4px;
                
                span {
                    @include fs(15, 18, semibold);
                    
                    display: block;
                    margin-bottom: 6px;
                }
            }
            
            p {
                @include fs(16, 22);
                
                &.targetgroups {
                    @include fs(15, 18);
                    
                    i {
                        font-style: italic;
                        display: inline-block;
                        margin-right: 5px;
                    }
                    
                    strong {
                        @include fst(semibold);
                    }
                    
                    &:before {
                        //position: absolute;
                        display: block;
                        content: '';
                        width: 63px;
                        height: 1px;
                        margin-bottom: 4px;
                        overflow: hidden;
                        background-color: black;
                    }
                }
                
                &.link {
                    text-align: right;
                    margin-bottom: 0;
                    
                    a {
                        &:before {
                            @include addIcon('linkStandardBold', 20px);
                            //content: '>';
                            //display: inline-block;
                            text-decoration: none;
                            //font-size: 20px;
                            //font-weight: 700;
                            color: black;
                        }
                        
                        &:after {
                            position: absolute;
                            inset: 0;
                            content: '';
                        }
                    }
                }
            }
        }
    }
    
    
    @include media-breakpoint-down(sm) {
        &__item {
            &.hide-on-mobile {
                display: none;
            }
        }
    }
    
    @include media-breakpoint-up(md) {
        &__item {
            width: 50%;
        }
    }
    
    @include media-breakpoint-up(lg) {
        &__item {
            width: 33.333%;
            
            &--first {
                .top {
                    p {
                        @include fsc(32, 35);
                    }
                }
            }
            
            
            &--last {
                h3 {
                    @include fsc(32, 35);
                    
                }
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        &__item {
            width: 25%;
        }
    }
}

$offset: 0.5s;
$interval: 0.3s;

$transitionSpeed: 0.3s;

$tileHeight: 521px;
$tileWidth: 336px;

.product-tile-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.product-tile {
    //height: 100%;
    //height: $tileHeight;
    //background-color: grey;
    width: calc(33.33333% - 12px);
    margin-bottom: 12px;
    margin-right: 12px;
    
    
    img {
        width: 100%;
        height: auto;
    }
    
    &-category {
        transition: width $offset ease-in-out;
        
        .active & {
            //width: calc(25% - 12px);

            width: $tileWidth;
            height: $tileHeight;
            
            //width: 100%;
            //height: 100px;
            //
            //img {
            //    height: 100px;
            //
            //}
            //background-color: map-get($button-bg-colors, 'darkPetrol');
            //border-radius: 16px;
            //
            //img {
            //    display: none;
            //}
        }
    }
    
    
    &-content {
        //display: none;
        display: block;
        position: relative;
        overflow-x: hidden;
        //width: calc(25% - 12px);
        width: $tileWidth;
        height: $tileHeight;
        //height: 100%;
        margin-right: 12px;
        //transform: translateX(0);
        //transition: opacity 1.5s ease-in-out 0.5s, width 0.5s ease-out 0.0s;
        transition: width $transitionSpeed linear 0.0s, opacity 0s linear $transitionSpeed;
        
        &.tile-first {
            transition: width $transitionSpeed ease-in 0.0s, opacity 0s linear $transitionSpeed;
        }
        
        .inside {
            height: $tileHeight;
            width: $tileWidth;
            opacity: 1;
            //width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            //left: 0;
            //transition: left 2.5s ease-in-out 0.0s;
            transform: translateX(0);
            //transition: transform 2.5s ease-in-out 0.0s;
            transition: opacity 3s ease-out;
        }
        
        &:not(.open) {
            //transition: opacity 0.25s ease-in 0.25s;
            //transition: width 0.5s linear;
            //display: none;
            //left: -100%;
            width: 0;
            height: 0;
            //opacity: 0;
            margin-right: 0;
            transition: width $transitionSpeed linear 0.0s, opacity 0s linear $transitionSpeed, margin-right 0s linear $transitionSpeed;
            
            .inside {
                //transform: translateX(-100%);
                //transition: transform 1s ease-out 0.0s;
                opacity: 0;
            }
        }
        
        &.open {
            //display: block;
            //transition: width 2s linear;
        }
        
        //> div {
        //    font-size: 80px;
        //    line-height: $tileHeight;
        //    text-align: center;
        //    color: white;
        //    height: inherit;
        //}
    }
}

$tileColors: 'red', 'blue', 'green', 'orange', 'cyan', 'yellow';

@each $color in $tileColors {
    .product-tile-#{$color} {
        background-color: #{$color};
    }
}