@mixin headlineLevel1() {
    @include fs(40,40,xbold);
    
    > span {
        @include fs(24,29,semibold);
        
        display: block;
        margin-top: 12px;
    }

    @include media-breakpoint-up(md) {
        @include fs(48,48,xbold);
    }

    @include media-breakpoint-up(xl) {
        @include fs(56,56,xbold);
    }
    
    @include media-breakpoint-up(xxl) {
        @include fs(72,72,xbold);
    }
}

@mixin headlineLevel2() {
    @include fs(28,31,semibold);
    
    @include media-breakpoint-up(md) {
        @include fs(32,35,semibold);
    }
}

@mixin headlineLevel3() {
    @include fs(24,29,semibold);
    
    @include media-breakpoint-up(md) {
        @include fs(28,34,semibold);
    }
}

@mixin headlineLevel4() {
    @include fs(22,26,semibold);
    
    @include media-breakpoint-up(md) {
        @include fs(24,29,semibold);
    }
}

@mixin headlineLevel5() {
    @include fs(20,24,semibold);
    
    @include media-breakpoint-up(md) {
        @include fs(20,24,semibold);
    }
}

//@mixin headlineLevel3() {
//    @include fs(20,24,xbold);
//
//    @include media-breakpoint-up(sm) {
//        @include fs(36,40,xbold);
//    }
//}
//
//@mixin headlineLevel4() {
//    @include fs(18,26,xbold);
//
//    @include media-breakpoint-up(sm) {
//        @include fs(20,26,xbold);
//    }
//}
//
//@mixin sublineLevel1() {
//    @include fs(18,26,med);
//}
//
//@mixin sublineLevel2() {
//    @include fs(18,26,med);
//}
//
//@mixin sublineLevel3() {
//    @include fs(18,26,med);
//}
//
//@mixin sublineLevel4() {
//    @include fs(18,26,med);
//}
