@mixin marginLG() {
    margin-bottom: $margin-bottom-large;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: $margin-bottom-large-lg;
    }
}

@mixin marginSM() {
    margin-bottom: $margin-bottom-small;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: $margin-bottom-small-lg;
    }
}

@mixin marginXS() {
    margin-bottom: $margin-bottom-xsmall;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: $margin-bottom-xsmall-lg;
    }
}

@mixin marginGridBottom() {
    margin-bottom: 24px;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
    }
}

@mixin marginTopXS() {
    margin-top: $margin-bottom-xsmall;
    
    @include media-breakpoint-up(lg) {
        margin-top: $margin-bottom-xsmall-lg;
    }
}

@mixin marginHeadline() {
    margin-bottom: 24px;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
    }
}

@mixin marginHeadlineStandard() {
    margin-bottom: 8px;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
    }
}

@mixin marginTopStd() {
    margin-top: 32px;
    
    @include media-breakpoint-up(lg) {
        margin-top: 40px;
    }
}

@mixin marginBottomStd() {
    margin-bottom: 32px;
    
    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }
}

@mixin marginLeftCol() {
    margin-bottom: 16px;
}