//@font-face {
//    font-family: icomoon;
//    src: url('#{$font-path}/icomoon.eot?ccifkl');
//    src:
//        url('#{$font-path}/icomoon.eot?ccifkl#iefix') format('embedded-opentype'),
//        url('#{$font-path}/icomoon.ttf?ccifkl') format('truetype'),
//        url('#{$font-path}/icomoon.woff?ccifkl') format('woff'),
//        url('#{$font-path}/icomoon.svg?ccifkl#icomoon') format('svg');
//    font-weight: normal;
//    font-style: normal;
//    font-display: block;
//}

//@font-face {
//    font-family: webfont-xbold;
//    src: url('#{$font-path}/webfont-xbold.woff') format('woff'), url('#{$font-path}/webfont-xbold.woff2') format('woff2');
//}
//
//@font-face {
//    font-family: webfont-xbold-ita;
//    src: url('#{$font-path}/webfont-xbold-ita.woff') format('woff'), url('#{$font-path}/webfont-xbold-ita.woff2') format('woff2');
//}
//
//@font-face {
//    font-family: webfont-bold;
//    src: url('#{$font-path}/webfont-bold.woff') format('woff'), url('#{$font-path}/webfont-bold.woff2') format('woff2');
//}
//
//@font-face {
//    font-family: webfont-semibold;
//    src: url('#{$font-path}/webfont-semibold.woff') format('woff'), url('#{$font-path}/webfont-semibold.woff2') format('woff2');
//}
//
//@font-face {
//    font-family: webfont-semibold-ita;
//    src: url('#{$font-path}/webfont-semibold-ita.woff') format('woff'), url('#{$font-path}/webfont-semibold-ita.woff2') format('woff2');
//}

@font-face {
    font-family: klavika-reg;
    src: url('#{$font-path}/KlavikaBasic-Regular.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Regular.woff2') format('woff2');
}

@font-face {
    font-family: klavika-ita;
    src: url('#{$font-path}/KlavikaBasic-RegularItalic.woff') format('woff'), url('#{$font-path}/KlavikaBasic-RegularItalic.woff2') format('woff2');
}

@font-face {
    font-family: klavika-medium;
    src: url('#{$font-path}/KlavikaBasic-Medium.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Medium.woff2') format('woff2');
}

@font-face {
    font-family: klavika-bold;
    src: url('#{$font-path}/KlavikaBasic-Bold.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Bold.woff2') format('woff2');
}

@font-face {
    font-family: klavika-black;
    src: url('#{$font-path}/KlavikaDisplay-Black.woff') format('woff'), url('#{$font-path}/KlavikaDisplay-Black.woff2') format('woff2');
}

//@font-face {
//    font-family: klavika-basic-medium;
//    src: url('#{$font-path}/KlavikaBasic-Medium.eot?') format('eot'), url('#{$font-path}/KlavikaBasic-Medium.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Medium.woff2') format('woff2');
//}
//
//@font-face {
//    font-family: klavika-basic-bold;
//    src: url('#{$font-path}/KlavikaBasic-Bold.eot?') format('eot'), url('#{$font-path}/KlavikaBasic-Bold.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Bold.woff2') format('woff2');
//}
//
////@font-face {
////    font-family: klavika-basic-light;
////    src: url('#{$font-path}/KlavikaBasic-Light.eot?') format('eot'), url('#{$font-path}/KlavikaBasic-Light.woff') format('woff'), url('#{$font-path}/KlavikaBasic-Light.woff2') format('woff2');
////}
//
//@font-face {
//    font-family: klavika-basic-italic;
//    src: url('#{$font-path}/KlavikaBasic-RegularItalic.eot?') format('eot'), url('#{$font-path}/KlavikaBasic-RegularItalic.woff') format('woff'), url('#{$font-path}/KlavikaBasic-RegularItalic.woff2') format('woff2');
//}
//
////@font-face {
////    font-family: klavika-basic-medium-italic;
////    src: url('#{$font-path}/KlavikaBasic-MediumItalic.eot?') format('eot'), url('#{$font-path}/KlavikaBasic-MediumItalic.woff') format('woff'), url('#{$font-path}/KlavikaBasic-MediumItalic.woff2') format('woff2');
////}
//
$font-reg: klavika-reg, 'sans-serif';
$font-ita: klavika-ita, 'sans-serif';
$font-medium: klavika-medium, 'sans-serif';
$font-bold: klavika-bold, 'sans-serif';
$font-display: klavika-black, 'sans-serif';

//$font-display: "klavika-web-display", sans-serif;


//$font-web-xbold: webfont-xbold, 'sans-serif';
//$font-web-xbold-ita: webfont-xbold-ita, 'sans-serif';
//$font-web-bold: webfont-bold, 'sans-serif';
//$font-web-semibold: webfont-semibold, 'sans-serif';
//$font-web-semibold-ita: webfont-semibold-ita, 'sans-serif';

//$font-kv-med: ff-zwo-web-pro, 'sans-serif';
//$font-kv-bold: ff-zwo-web-pro, 'sans-serif';
////$font-kv-lig: klavika-basic-light, 'sans-serif';
//$font-kv-ita: ff-zwo-web-pro, 'sans-serif';
////$font-kv-mit: klavika-basic-medium-italic, 'sans-serif';
