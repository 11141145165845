.mod_newsreader {
    .info {
        //display: none;
        
        @include fs(15, 18);
        
        margin-bottom: 16px;
        
    }
    
    .backlink-container {
        @include marginTopStd;
        
        @include media-breakpoint-up(lg) {
            margin-left: calc(33.333% + 12px);
        }
    }
}

.mod_newsmenu {
    //display: flex;
    //justify-content: flex-end;
    
    margin-bottom: 20px;
    
    ul {
        @include resetList;
        
        display: flex;
        
        li {
            a,
            strong {
                color: black;
                margin-right: 15px;
                text-decoration: underline;
            }
        }
    }
}

.mod_newsarchive,
.mod_newslist {
    > h2,
    > h3
    > h4 {
        @include headlineLevel2;
    }
    
    @include media-breakpoint-up(md) {
        .news-container {
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            margin-right: -12px;
            
            > div {
                width: 50%;
                padding: 12px;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        .news-container {
            > div {
                width: 33.333%;
                
                .inside {
                    height: 100%;
                }
            }
        }
    }

}

.layout_list {
    @include imageStandardNoCaption;
    
    hyphens: auto;
    //height: 100%;
    
    .inside {
        display: flex;
        flex-direction: column;
        //height: 100%;
        //background-color: white;
    }
    
    .content-container {
        height: 100%;
        position: relative;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        padding: 24px 0;
    }
    
    .ce_text {
        flex-grow: 2;
    }
    
    h2,
    h3,
    h4 {
        @include fs(20,22,semibold);
        
        margin-bottom: 4px;
    }
    
    p {
        @include fs(16,22);
        
        .info {
            @include fst(semibold);
        }
        
        &.meta {
            @include fs(15, 18);
            
            margin-bottom: 6px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        h2,
        h3,
        h4 {
            @include fsc(24,29);
        }
    }
}

.mod_newslist {
    .row .layout_list {
        height: 100%;
        
        .inside {
            height: 100%;
        }
    }
}