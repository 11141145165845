.job-list {
    @include marginLG;
    
    .job-teaser {
    
    }
}

.job-teaser {
    @include marginBottomStd;
    
    border-bottom: 1px solid black;
    
    .headline-container {
        h2,
        h3 {
            @include headlineLevel2;
            //@include marginHeadline;
        }
    }
    
    p {
        @include paragraphStandard;
        
        &.content {
            @include marginSM;
        }
        
        &.link {
            @include marginBottomStd;
        }
        
        a {
            @include linkStandard;
        }
    }
    
    //p.link {
    //    text-align: right;
    //    @include fs(24, 29);
    //
    //    a {
    //        &:before {
    //            content: '>';
    //            display: inline-block;
    //            text-decoration: none;
    //            font-size: 20px;
    //            font-weight: 700;
    //            color: black;
    //        }
    //
    //        &:after {
    //            position: absolute;
    //            inset: 0;
    //            content: '';
    //        }
    //    }
    //}
    //
    //@include media-breakpoint-up(lg) {
    //    .headline-container {
    //        @include fs(24, 29);
    //    }
    //}
    
}

.job-detail {
    .link-container {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    
    .table-meta {
        margin-right: 48px;
    }
    
    .content-text {
        @include marginLG;
    }
    
    @include media-breakpoint-down(lg) {
        .content-table {
            @include marginLG;
        }
    }
    
    .text-left {
        //@include fs(16,22);
    }
    
    .content-table,
    .content-text {
        
        .table-headline {
            @include fs(30,35,xbold);
        }
        
        .job-headline {
            @include fs(36,40,semibold);
            
            margin-bottom: 40px;
        }
        
    }

}