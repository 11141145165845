$font-icon: (
    'accordion-closed': "\e900",
    'accordion-opened': "\e901",
    'arrow-breadcrumb': "\e902",
    'arrow-button-thema': "\e903",
    'arrow-menu-closed': "\e904",
    'arrow-menu-opened': "\e905",
    'arrow-teaser': "\e906",
    'arrowShowAll': "\e907",
    'close': "\e908",
    'community': "\e909",
    'consent-active .path1': "\e90a",
    'consent-active .path2': "\e90b",
    'consent-inactive .path1': "\e90c",
    'consent-inactive .path2': "\e90d",
    'contrast': "\e90e",
    'dropdown-closed': "\e90f",
    'dropdown-opened': "\e910",
    'facebook': "\e911",
    'filter-add': "\e912",
    'filter-close': "\e913",
    'filter-plus': "\e914",
    'filter-substract': "\e915",
    'form-checkmark': "\e916",
    'frag-dich': "\e917",
    'gebaerdensprache': "\e918",
    'instagram': "\e919",
    'language-de': "\e91a",
    'language-en': "\e91b",
    //'leichte-sprache': "\e91c",
    'linkAnchorBold': "\e91d",
    'linkBackCTA': "\e91e",
    'linkCTA': "\e91f",
    'linkDownloadBold': "\e920",
    'linkDownloadCTA': "\e921",
    'linkExternalBold': "\e922",
    'linkExternalCTA': "\e923",
    'linkMailToBold': "\e924",
    'linkMailtoCTA': "\e925",
    'linkStandardBold': "\e926",
    'mediawall-blog': "\e927",
    'menu': "\e928",
    'merkzettel-checkmark': "\e929",
    'merkzettel-minus': "\e92a",
    'merkzettel-plus': "\e92b",
    'overview': "\e92c",
    'play-default .path1': "\e92d",
    'play-default .path2': "\e92e",
    'play-hover .path1': "\e92f",
    'play-hover .path2': "\e930",
    'quote': "\e931",
    'search-menu': "\e932",
    'slider-left': "\e933",
    'slider-right': "\e934",
    'tab-closed': "\e935",
    'to-do': "\e936",
    'wusstest-du': "\e937",
    'x': "\e938",
    'tiktok': "\e939",
    'leichte-sprache': "\e93a",
    
);

@mixin replaceWithIconFont($iconWidth, $iconHeight) {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    padding: 0;
    border: 0;
    width: $iconWidth;
    height: $iconHeight;
    color: black;
    
    &:before {
        content: '';
        font-family: 'iconfont' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: $iconHeight;
        line-height: $iconHeight;
        text-align: center;
        display: inline-block;
        width: $iconWidth;
        height: $iconHeight;
        color: inherit;
        
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@mixin addIcon($name, $fontSize) {
    content: map-get($font-icon, $name);
    font-family: 'iconfont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: $fontSize;
    line-height: $fontSize;
    text-align: center;
    display: inline-block;
    color: inherit;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin setIcon($name) {
    content: map-get($font-icon, $name)
}

@mixin setIconSize($fontSize) {
    font-size: $fontSize;
    line-height: $fontSize;
}

@mixin setLinkIcon($name) {
    &:before {
        content: map-get($font-icon, $name)
    }
}

//[class^="icon-"], [class*=" icon-"] {
//    /* use !important to prevent issues with browser extensions that change fonts */
//    font-family: 'icomoon' !important;
//    speak: never;
//    font-style: normal;
//    font-weight: normal;
//    font-variant: normal;
//    text-transform: none;
//    line-height: 1;
//
//    /* Better Font Rendering =========== */
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//}

